"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSetting = exports.getSettings = exports.getSettingById = exports.fileTransferHeaders = exports.deleteSetting = exports.defaultSettingData = exports.createSetting = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultSettingData = {
  id: 0,
  bankInformation: '',
  bidExpiryHours: 4,
  bidApprovalHours: 4,
  automatedCreditNoteGenerationDays: 0,
  expiryDays: 0,
  bookingAmountThreshold: 0,
  transactionPercent: 0,
  bookingPercent: 0,
  cancellationCharges: 0,
  referralAmount: 0,
  codRate: 0,
  minimumCodCharge: 0
};
exports.defaultSettingData = defaultSettingData;
var getSettings = function getSettings(params) {
  return (0, _request.default)({
    url: '/settings',
    method: 'get',
    params: params
  });
};
exports.getSettings = getSettings;
var getSettingById = function getSettingById(id) {
  return (0, _request.default)({
    url: "/settings/".concat(id),
    method: 'get'
  });
};
exports.getSettingById = getSettingById;
var updateSetting = function updateSetting(id, data) {
  return (0, _request.default)({
    url: "/settings/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateSetting = updateSetting;
var deleteSetting = function deleteSetting(id) {
  return (0, _request.default)({
    url: "/settings/".concat(id),
    method: 'delete'
  });
};
exports.deleteSetting = deleteSetting;
var createSetting = function createSetting(data) {
  return (0, _request.default)({
    url: '/settings/',
    method: 'post',
    data: data
  });
};
exports.createSetting = createSetting;