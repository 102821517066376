"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAdmin = exports.register = exports.logout = exports.login = exports.getAdmins = exports.getAdminInfo = exports.getAdminById = exports.getAdminByEmail = exports.deleteAdminById = exports.deleteAdmin = exports.defaultAdminData = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/objectSpread2"));
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultAdminData = {
  id: 0,
  enabled: true,
  fullName: "",
  emailAddress: "",
  mobileNumber: "",
  password: undefined,
  newPassword: undefined,
  avatar: "",
  passwordChangedTimestamp: null,
  adminsRoleId: 1,
  adminsRole: {
    id: null,
    name: ""
  }
};
exports.defaultAdminData = defaultAdminData;
var getAdmins = function getAdmins(params) {
  return (0, _request.default)({
    url: "/admins",
    method: "get",
    params: params
  });
};
exports.getAdmins = getAdmins;
var getAdminById = function getAdminById(id) {
  return (0, _request.default)({
    url: "/admins/".concat(id),
    method: "get"
  });
};
exports.getAdminById = getAdminById;
var getAdminInfo = function getAdminInfo(data) {
  return (0, _request.default)({
    url: "/admins/me",
    method: "post",
    data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
      createdBy: 0
    })
  });
};
exports.getAdminInfo = getAdminInfo;
var getAdminByEmail = function getAdminByEmail(adminemail) {
  return (0, _request.default)({
    url: "/admins/".concat(adminemail),
    method: "get"
  });
};
exports.getAdminByEmail = getAdminByEmail;
var updateAdmin = function updateAdmin(id, data) {
  return (0, _request.default)({
    url: "/admins/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateAdmin = updateAdmin;
var deleteAdmin = function deleteAdmin(adminname) {
  return (0, _request.default)({
    url: "/admins/".concat(adminname),
    method: "delete"
  });
};
exports.deleteAdmin = deleteAdmin;
var deleteAdminById = function deleteAdminById(id) {
  return (0, _request.default)({
    url: "/admins/".concat(id),
    method: "delete"
  });
};
exports.deleteAdminById = deleteAdminById;
var login = function login(data) {
  return (0, _request.default)({
    url: "/admins/login",
    method: "post",
    data: data
  });
};
exports.login = login;
var logout = function logout() {
  return (0, _request.default)({
    url: "/admins/logout",
    method: "post"
  });
};
exports.logout = logout;
var register = function register(data) {
  return (0, _request.default)({
    url: "/admins/",
    method: "post",
    data: data
  });
};
exports.register = register;