var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _vm.userscount
                ? [
                    _c("router-link", { attrs: { to: "/users/list" } }, [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-panel-icon-wrapper icon-people"
                          },
                          [
                            _c("svg-icon", {
                              staticClass: "card-panel-icon",
                              attrs: { name: "peoples" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "card-panel-description" },
                          [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v(" Users ")
                            ]),
                            _c("count-to", {
                              staticClass: "card-panel-num",
                              attrs: {
                                "start-val": 0,
                                "end-val": _vm.userscount,
                                duration: 2600
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                : [
                    _c("div", { staticClass: "card-panel" }, [
                      _c(
                        "div",
                        { staticClass: "card-panel-icon-wrapper icon-people" },
                        [
                          _c("svg-icon", {
                            staticClass: "card-panel-icon",
                            attrs: { name: "peoples" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "card-panel-description" },
                        [
                          _c("div", { staticClass: "card-panel-text" }, [
                            _vm._v(" Users ")
                          ]),
                          _c("count-to", {
                            staticClass: "card-panel-num",
                            attrs: {
                              "start-val": 0,
                              "end-val": _vm.userscount,
                              duration: 2600
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
            ],
            2
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _vm.productscount > 0
                ? [
                    _c("router-link", { attrs: { to: "/product/list" } }, [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-panel-icon-wrapper icon-shopping"
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-box card-panel-icon"
                            })
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "card-panel-description" },
                          [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v(" Products ")
                            ]),
                            _c("count-to", {
                              staticClass: "card-panel-num",
                              attrs: {
                                "start-val": 0,
                                "end-val": _vm.productscount,
                                duration: 3000
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                : [
                    _c("div", { staticClass: "card-panel" }, [
                      _c(
                        "div",
                        {
                          staticClass: "card-panel-icon-wrapper icon-shopping"
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-box card-panel-icon"
                          })
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "card-panel-description" },
                        [
                          _c("div", { staticClass: "card-panel-text" }, [
                            _vm._v(" Products ")
                          ]),
                          _c("count-to", {
                            staticClass: "card-panel-num",
                            attrs: {
                              "start-val": 0,
                              "end-val": _vm.productscount,
                              duration: 3000
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
            ],
            2
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _vm.orderscount > 0
                ? [
                    _c("router-link", { attrs: { to: "/order/list" } }, [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          { staticClass: "card-panel-icon-wrapper icon-money" },
                          [
                            _c("svg-icon", {
                              staticClass: "card-panel-icon",
                              attrs: { name: "shopping" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "card-panel-description" },
                          [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v(" Orders ")
                            ]),
                            _c("count-to", {
                              staticClass: "card-panel-num",
                              attrs: {
                                "start-val": 0,
                                "end-val": _vm.orderscount,
                                duration: 3200
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                : [
                    _c("div", { staticClass: "card-panel" }, [
                      _c(
                        "div",
                        { staticClass: "card-panel-icon-wrapper icon-money" },
                        [
                          _c("svg-icon", {
                            staticClass: "card-panel-icon",
                            attrs: { name: "inr" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "card-panel-description" },
                        [
                          _c("div", { staticClass: "card-panel-text" }, [
                            _vm._v(" Orders ")
                          ]),
                          _c("count-to", {
                            staticClass: "card-panel-num",
                            attrs: {
                              "start-val": 0,
                              "end-val": _vm.orderscount,
                              duration: 3200
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
            ],
            2
          ),
          _vm.isSuperAdmin()
            ? _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _vm.bidscount > 0
                    ? [
                        _c("router-link", { attrs: { to: "/bid/list" } }, [
                          _c("div", { staticClass: "card-panel" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card-panel-icon-wrapper icon-message"
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "card-panel-icon",
                                  attrs: { name: "chart" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "card-panel-description" },
                              [
                                _c("div", { staticClass: "card-panel-text" }, [
                                  _vm._v(" Pending Bids ")
                                ]),
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": _vm.bidscount,
                                    duration: 3200
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ]
                    : [
                        _c("div", { staticClass: "card-panel" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card-panel-icon-wrapper icon-message"
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "card-panel-icon",
                                attrs: { name: "chart" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "card-panel-description" },
                            [
                              _c("div", { staticClass: "card-panel-text" }, [
                                _vm._v(" Pending Bids ")
                              ]),
                              _c("count-to", {
                                staticClass: "card-panel-num",
                                attrs: {
                                  "start-val": 0,
                                  "end-val": _vm.bidscount,
                                  duration: 3200
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                ],
                2
              )
            : _vm._e(),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to:
                      "/customer/seller-list?isSeller=false&isSellerRegistered=true"
                  }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-people" },
                      [
                        _c("svg-icon", {
                          staticClass: "card-panel-icon",
                          attrs: { name: "peoples" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "card-panel-description" },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v(" Seller"),
                          _c("br"),
                          _vm._v(" Requests ")
                        ]),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.sellerRequestCount,
                            duration: 2600
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c(
                "router-link",
                { attrs: { to: "/product/list?status=Pending" } },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-shopping" },
                      [_c("i", { staticClass: "el-icon-box card-panel-icon" })]
                    ),
                    _c(
                      "div",
                      { staticClass: "card-panel-description" },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v(" Product"),
                          _c("br"),
                          _vm._v(" Requests ")
                        ]),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.productRequestCount,
                            duration: 2600
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c("router-link", { attrs: { to: "/users-documents/list" } }, [
                _c("div", { staticClass: "card-panel" }, [
                  _c(
                    "div",
                    { staticClass: "card-panel-icon-wrapper icon-money" },
                    [
                      _c("i", {
                        staticClass: "el-icon-s-claim card-panel-icon"
                      })
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v(" KYC Requests ")
                      ]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.kycRequestCount,
                          duration: 2600
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 16 } },
            [
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _vm._v(" Orders Status ")
              ]),
              _c(
                "el-row",
                { staticClass: "panel-group", attrs: { gutter: 20 } },
                _vm._l(_vm.statuses, function(status) {
                  return _c(
                    "el-col",
                    {
                      key: status.status,
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 6, lg: 4 }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/order/list?status=" + status.status }
                        },
                        [
                          _c("div", { staticClass: "card-panel" }, [
                            _c(
                              "div",
                              { staticClass: "card-panel-description" },
                              [
                                _c("div", { staticClass: "card-panel-text" }, [
                                  _vm._v(" " + _vm._s(status.status) + " ")
                                ]),
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": status.count,
                                    duration: 2600
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 8 } },
            [
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _vm._v(" Orders Tickets ")
              ]),
              _c(
                "el-row",
                { staticClass: "panel-group", attrs: { gutter: 20 } },
                _vm._l(_vm.supportTicketCount, function(status) {
                  return _c(
                    "el-col",
                    {
                      key: status.status,
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 6, md: 6, lg: 6 }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: "/orders-tickets/list?status=" + status.status
                          }
                        },
                        [
                          _c("div", { staticClass: "card-panel" }, [
                            _c(
                              "div",
                              { staticClass: "card-panel-description" },
                              [
                                _c("div", { staticClass: "card-panel-text" }, [
                                  _vm._v(" " + _vm._s(status.status) + " ")
                                ]),
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": status.count * 1,
                                    duration: 2600
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }