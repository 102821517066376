"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _clipboard = require("./clipboard");
Object.keys(_clipboard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _clipboard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _clipboard[key];
    }
  });
});
var _elDraggableDialog = require("./el-draggable-dialog");
Object.keys(_elDraggableDialog).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _elDraggableDialog[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _elDraggableDialog[key];
    }
  });
});
var _permission = require("./permission");
Object.keys(_permission).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _permission[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _permission[key];
    }
  });
});
var _waves = require("./waves");
Object.keys(_waves).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _waves[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _waves[key];
    }
  });
});