"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/typeof");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.object.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/toConsumableArray"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _couponSubMenu = require("./coupon-sub-menu");
var _bidSubMenu = require("./subMenus/bid-sub-menu");
var _ordersTicketsSubMenu = require("./subMenus/orders-tickets-sub-menu");
var _walletTransactionSubMenu = require("./subMenus/wallet-transaction-sub-menu");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var orderRouter = {
  path: "/order",
  component: _index.default,
  redirect: "noredirect",
  name: "orderManagement.title",
  meta: {
    title: "orderManagement.title",
    icon: "shopping"
  },
  children: [{
    path: "/order/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order/list.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      noCache: true
    }
  }, {
    path: "/order/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order/manage.vue"));
      });
    },
    name: "orderManagement.orders.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderManagement.orders.edit",
      noCache: true,
      activeMenu: "/order/list",
      hidden: true
    }
  }, {
    path: "/order/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order/components/OrdersInfoDetails.vue"));
      });
    },
    name: "orderManagement.orders.info",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderManagement.orders.info",
      noCache: true,
      activeMenu: "/order/list",
      hidden: true
    }
  }, {
    path: "/order/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/order/manage.vue"));
      });
    },
    name: "orderManagement.orders.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "orderManagement.orders.add",
      noCache: true,
      activeMenu: "/order/list",
      hidden: true
    }
  }, {
    path: "/orders-sellers/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/orders-sellers/list.vue"));
      });
    },
    name: "Seller Orders",
    meta: {
      title: "orderManagement.ordersSeller.title",
      icon: "shopping",
      noCache: true
    }
  }, {
    path: "/orders-sellers/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/orders-sellers/manage.vue"));
      });
    },
    name: "orderManagement.ordersSeller.manage",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderManagement.ordersSeller.manage",
      noCache: true,
      activeMenu: "/order-sellers/list",
      hidden: true
    }
  }, {
    path: "/transaction/list/",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/transaction/list.vue"));
      });
    },
    name: "Transaction",
    meta: {
      title: "orderManagement.transaction.title",
      noCache: true,
      icon: "inr"
    }
  }, {
    path: "/transaction/list/:orderId(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/transaction/list.vue"));
      });
    },
    name: "Transaction",
    meta: {
      title: "orderManagement.transaction.title",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/transaction/add/:orderId(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/transaction/manage.vue"));
      });
    },
    name: "orderManagement.transaction.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "orderManagement.transaction.add",
      noCache: true,
      activeMenu: "/transaction/list/:id(.*)",
      hidden: true
    }
  }, {
    path: "/transaction/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/transaction/manage.vue"));
      });
    },
    name: "orderManagement.transaction.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderManagement.transaction.edit",
      noCache: true,
      activeMenu: "/transaction/list/:id(.*)",
      hidden: true
    }
  }].concat((0, _toConsumableArray2.default)(_walletTransactionSubMenu.walletTransactionSubMenu), (0, _toConsumableArray2.default)(_bidSubMenu.bidsSubMenu), (0, _toConsumableArray2.default)(_ordersTicketsSubMenu.ordersTicketsSubMenu), (0, _toConsumableArray2.default)(_couponSubMenu.couponsSubMenu))
};
var _default = orderRouter;
exports.default = _default;