"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOffices = exports.updateMustTry = exports.getMustTrys = exports.getMustTryById = exports.fileTransferHeaders = exports.deleteOffices = exports.defaultMustTryData = exports.createOffices = exports.createMustTry = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultMustTryData = {
  id: 0,
  enabled: true,
  pageName: "",
  title: "",
  text: "",
  image: "",
  priority: 0,
  categoryId: 0,
  createdTimestamp: "",
  updatedTimestamp: ""
};
exports.defaultMustTryData = defaultMustTryData;
var getMustTrys = function getMustTrys(params) {
  return (0, _request.default)({
    url: "/must-try-category",
    method: "get",
    params: params
  });
};
exports.getMustTrys = getMustTrys;
var getMustTryById = function getMustTryById(id) {
  return (0, _request.default)({
    url: "/must-try-category/".concat(id),
    method: "get"
  });
};
exports.getMustTryById = getMustTryById;
var updateMustTry = function updateMustTry(id, data) {
  return (0, _request.default)({
    url: "/must-try-category/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateMustTry = updateMustTry;
var createMustTry = function createMustTry(data) {
  return (0, _request.default)({
    url: "/must-try-category/",
    method: "post",
    data: data
  });
};
exports.createMustTry = createMustTry;
var updateOffices = function updateOffices(id, data) {
  return (0, _request.default)({
    url: "/offices/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateOffices = updateOffices;
var deleteOffices = function deleteOffices(id) {
  return (0, _request.default)({
    url: "/offices/".concat(id),
    method: "delete"
  });
};
exports.deleteOffices = deleteOffices;
var createOffices = function createOffices(data) {
  return (0, _request.default)({
    url: "/offices/",
    method: "post",
    data: data
  });
};
exports.createOffices = createOffices;