"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePlan = exports.getPlans = exports.getPlanById = exports.deletePlan = exports.defaultPlanData = exports.createPlan = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultPlanData = {
  id: 0,
  enabled: true,
  name: undefined,
  validity: 1,
  salePrice: 0,
  regularPrice: 1,
  taxId: null,
  icon: null,
  tax: {
    id: null
  }
};
exports.defaultPlanData = defaultPlanData;
var getPlans = function getPlans(params) {
  return (0, _request.default)({
    url: '/plans',
    method: 'get',
    params: params
  });
};
exports.getPlans = getPlans;
var getPlanById = function getPlanById(id) {
  return (0, _request.default)({
    url: "/plans/".concat(id),
    method: 'get'
  });
};
exports.getPlanById = getPlanById;
var updatePlan = function updatePlan(id, data) {
  return (0, _request.default)({
    url: "/plans/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updatePlan = updatePlan;
var deletePlan = function deletePlan(id) {
  return (0, _request.default)({
    url: "/plans/".concat(id),
    method: 'delete'
  });
};
exports.deletePlan = deletePlan;
var createPlan = function createPlan(data) {
  return (0, _request.default)({
    url: '/plans/',
    method: 'post',
    data: data
  });
};
exports.createPlan = createPlan;