var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Status", required: "" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { name: "status", placeholder: "Select" },
                    model: {
                      value: _vm.formData.status,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status"
                    }
                  },
                  _vm._l(_vm.orderOptions, function(item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: {
                        required: "",
                        label: item.label,
                        value: item.key
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm.formData.status === "Order Dispatched"
              ? [
                  _c(
                    "el-form-item",
                    { attrs: { label: "LR Number", prop: "lrNumber" } },
                    [
                      _c("el-input", {
                        attrs: { name: "lrNumber", placeholder: "LR Number" },
                        model: {
                          value: _vm.formData.lrNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "lrNumber", $$v)
                          },
                          expression: "formData.lrNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "LR Receipt", prop: "lrReceipt" } },
                    [
                      _c("file-upload", {
                        attrs: {
                          "file-list": _vm.lrReceipt,
                          url: "/orders/upload"
                        },
                        on: {
                          change: function($event) {
                            _vm.formData.lrReceipt = $event.response
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Invoice Attachment",
                        prop: "invoiceAttachment"
                      }
                    },
                    [
                      _c("file-upload", {
                        attrs: {
                          "file-list": _vm.invoiceAttachment,
                          url: "/orders/upload"
                        },
                        on: {
                          change: function($event) {
                            _vm.formData.invoiceAttachment = $event.response
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "Comments", prop: "comments" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    name: "comments",
                    placeholder: "share comments",
                    maxlength: "200",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.formData.comments,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "comments", $$v)
                    },
                    expression: "formData.comments"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }