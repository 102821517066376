"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSellersProductsVariation = exports.getSellersProductsVariations = exports.getSellersProductsVariationById = exports.deleteSellersProductsVariation = exports.defaultSellersProductsVariationData = exports.createSellersProductsVariation = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultSellersProductsVariationData = {
  id: 0,
  enabled: true,
  sellerId: undefined,
  seller: {
    id: null
  },
  productId: undefined,
  product: {
    id: null
  },
  productsVariationId: undefined,
  productsVariation: {
    id: null
  },
  name: '',
  unitPrice: null,
  salePrice: null,
  sku: null,
  image: null,
  stockStatus: undefined,
  stockQuantity: null,
  lowStockThreshold: 1,
  manageStock: undefined
};
exports.defaultSellersProductsVariationData = defaultSellersProductsVariationData;
var getSellersProductsVariations = function getSellersProductsVariations(params) {
  return (0, _request.default)({
    url: '/sellers-products-variations',
    method: 'get',
    params: params
  });
};
exports.getSellersProductsVariations = getSellersProductsVariations;
var getSellersProductsVariationById = function getSellersProductsVariationById(id) {
  return (0, _request.default)({
    url: "/sellers-products-variations/".concat(id),
    method: 'get'
  });
};
exports.getSellersProductsVariationById = getSellersProductsVariationById;
var updateSellersProductsVariation = function updateSellersProductsVariation(id, data) {
  return (0, _request.default)({
    url: "/sellers-products-variations/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateSellersProductsVariation = updateSellersProductsVariation;
var deleteSellersProductsVariation = function deleteSellersProductsVariation(id) {
  return (0, _request.default)({
    url: "/sellers-products-variations/".concat(id),
    method: 'delete'
  });
};
exports.deleteSellersProductsVariation = deleteSellersProductsVariation;
var createSellersProductsVariation = function createSellersProductsVariation(data) {
  return (0, _request.default)({
    url: '/sellers-products-variations/',
    method: 'post',
    data: data
  });
};
exports.createSellersProductsVariation = createSellersProductsVariation;