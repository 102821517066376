"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/typeof");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.object.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/toConsumableArray"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _pincodeSubMenu = require("./subMenus/pincode-sub-menu");
var _userSubMenu = require("./subMenus/user-sub-menu");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var customerRouter = {
  path: "/customer",
  component: _index.default,
  redirect: "noredirect",
  name: "customerManagement.title",
  meta: {
    title: "customerManagement.title",
    icon: "peoples"
  },
  children: [{
    path: "/organization/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/organization/list.vue"));
      });
    },
    name: "Organization",
    meta: {
      icon: "building",
      title: "customerManagement.organization.title",
      noCache: true
    }
  }, {
    path: "/organization/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/organization/manage.vue"));
      });
    },
    name: "customerManagement.organization.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "customerManagement.organization.edit",
      noCache: true,
      activeMenu: "/organization/list",
      hidden: true
    }
  }, {
    path: "/organization/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/organization/manage.vue"));
      });
    },
    name: "customerManagement.organization.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "customerManagement.organization.add",
      noCache: true,
      activeMenu: "/organization/list",
      hidden: true
    }
  }, {
    path: "/contact/list/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contact/list.vue"));
      });
    },
    name: "customerManagement.contact.title",
    meta: {
      title: "customerManagement.contact.title",
      hidden: true,
      noCache: true
    }
  }, {
    path: "/contact/add/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contact/manage.vue"));
      });
    },
    name: "customerManagement.contact.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "customerManagement.contact.add",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/contact/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contact/manage.vue"));
      });
    },
    name: "customerManagement.contact.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "customerManagement.contact.edit",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/address/list/:type/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/address/list.vue"));
      });
    },
    name: "Address",
    meta: {
      title: "customerManagement.address.title",
      hidden: true,
      noCache: true
    }
  }, {
    path: "/address/list/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/address/list.vue"));
      });
    },
    name: "Address",
    meta: {
      title: "customerManagement.address.title",
      hidden: true,
      noCache: true
    }
  }, {
    path: "/address/add/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/address/manage.vue"));
      });
    },
    name: "customerManagement.address.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "customerManagement.address.add",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/address/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/address/manage.vue"));
      });
    },
    name: "customerManagement.address.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "customerManagement.address.edit",
      noCache: true,
      hidden: true
    }
  }].concat((0, _toConsumableArray2.default)(_pincodeSubMenu.pincodesSubMenu), (0, _toConsumableArray2.default)(_userSubMenu.userSubMenu), [{
    path: "/users-logs/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/users-logs/list.vue"));
      });
    },
    name: "Logs",
    meta: {
      icon: "list",
      title: "customerManagement.userLog.title",
      noCache: true
    }
  }])
};
var _default = customerRouter;
exports.default = _default;