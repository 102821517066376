"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateQuestion = exports.getQuestions = exports.getQuestionById = exports.deleteQuestion = exports.defaultQuestionData = exports.createQuestion = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultQuestionData = {
  id: 0,
  enabled: true,
  questionText: '',
  allowMultiSelect: true
};
exports.defaultQuestionData = defaultQuestionData;
var getQuestions = function getQuestions(params) {
  return (0, _request.default)({
    url: '/questions',
    method: 'get',
    params: params
  });
};
exports.getQuestions = getQuestions;
var getQuestionById = function getQuestionById(id) {
  return (0, _request.default)({
    url: "/questions/".concat(id),
    method: 'get'
  });
};
exports.getQuestionById = getQuestionById;
var updateQuestion = function updateQuestion(id, data) {
  return (0, _request.default)({
    url: "/questions/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateQuestion = updateQuestion;
var deleteQuestion = function deleteQuestion(id) {
  return (0, _request.default)({
    url: "/questions/".concat(id),
    method: 'delete'
  });
};
exports.deleteQuestion = deleteQuestion;
var createQuestion = function createQuestion(data) {
  return (0, _request.default)({
    url: '/questions/',
    method: 'post',
    data: data
  });
};
exports.createQuestion = createQuestion;