"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/typeof");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.object.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/toConsumableArray"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _categorySubMenu = require("./subMenus/category-sub-menu");
var _sellersVariationsCitiesPricesSubMenu = require("./subMenus/sellers-variations-cities-prices-sub-menu");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var productRouter = {
  path: "/product",
  component: _index.default,
  redirect: "noredirect",
  name: "productManagement.title",
  meta: {
    title: "productManagement.title",
    icon: "almond"
  },
  children: [{
    path: "/brand/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/brand/list.vue"));
      });
    },
    name: "Brands",
    meta: {
      title: "productManagement.brand.title",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/brand/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/brand/manage.vue"));
      });
    },
    name: "productManagement.brand.manage",
    props: {
      isEdit: true
    },
    meta: {
      title: "productManagement.brand.manage",
      noCache: true,
      activeMenu: "/brand/list",
      hidden: true
    }
  }, {
    path: "/brand/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/brand/manage.vue"));
      });
    },
    name: "productManagement.brand.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.brand.manage",
      noCache: true,
      activeMenu: "/brand/list",
      hidden: true
    }
  }, {
    path: "/attribute/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute/list.vue"));
      });
    },
    name: "productManagement.attribute.title",
    meta: {
      title: "productManagement.attribute.title",
      noCache: true,
      icon: "sack"
    }
  }, {
    path: "/attribute/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute/manage.vue"));
      });
    },
    name: "productManagement.attribute.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.attribute.add",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/attribute/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute/manage.vue"));
      });
    },
    name: "productManagement.attribute.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "productManagement.attribute.edit",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/attribute-value/list/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute-value/list.vue"));
      });
    },
    name: "Attribute Value",
    meta: {
      title: "productManagement.attributevalue.title",
      noCache: true,
      activeMenu: "/attribute/list",
      hidden: true
    }
  }, {
    path: "/attribute-value/add/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute-value/manage.vue"));
      });
    },
    name: "Manage Attribute Value",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.attributevalue.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/attribute-value/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/attribute-value/manage.vue"));
      });
    },
    name: "productManagement.attributevalue.manage",
    props: {
      isEdit: true
    },
    meta: {
      title: "productManagement.attributevalue.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/product/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/products/list.vue"));
      });
    },
    name: "productManagement.product.title",
    meta: {
      title: "productManagement.product.title",
      icon: "almond",
      noCache: true
    }
  }, {
    path: "/product/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/products/manage.vue"));
      });
    },
    name: "productManagement.product.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.product.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/product/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/products/manage.vue"));
      });
    },
    name: "productManagement.product.manage",
    props: {
      isEdit: true
    },
    meta: {
      title: "productManagement.product.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/sellers-products-variations/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/sellers-products-variations/list.vue"));
      });
    },
    name: "productManagement.sellerVariation.title",
    meta: {
      title: "productManagement.sellerVariation.title",
      icon: "almond",
      noCache: true
    }
  }].concat((0, _toConsumableArray2.default)(_categorySubMenu.categoriesSubMenu), (0, _toConsumableArray2.default)(_sellersVariationsCitiesPricesSubMenu.sellersVariationsCitiesPriceSubMenu))
};
var _default = productRouter;
exports.default = _default;