var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("order.name") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.orderReference,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "orderReference", $$v)
              },
              expression: "listQuery.orderReference"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("organization.name") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.userOrganizationName,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "userOrganizationName", $$v)
              },
              expression: "listQuery.userOrganizationName"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("order.userName"), clearable: "" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery["user.fullName"],
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "user.fullName", $$v)
              },
              expression: "listQuery['user.fullName']"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("order.mobile"), clearable: "" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery["user.mobileNumber"],
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "user.mobileNumber", $$v)
              },
              expression: "listQuery['user.mobileNumber']"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("order.email"), clearable: "" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery["user.emailAddress"],
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "user.emailAddress", $$v)
              },
              expression: "listQuery['user.emailAddress']"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { clearable: "", placeholder: "Status" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.status,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status"
              }
            },
            _vm._l(_vm.statusOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.sort,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "sort", $$v)
                },
                expression: "listQuery.sort"
              }
            },
            _vm._l(_vm.sortOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c("br"),
          _c("VueCtkDateTimePicker", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { formatted: "DD-MM-YYYY", range: "" },
            on: { change: _vm.dateval },
            model: {
              value: _vm.dateRange,
              callback: function($$v) {
                _vm.dateRange = $$v
              },
              expression: "dateRange"
            }
          }),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]
          ),
          _vm.isSuperAdmin()
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: {
                    loading: _vm.downloadLoading,
                    type: "primary",
                    icon: "el-icon-download"
                  },
                  on: { click: _vm.handleExport }
                },
                [_vm._v(" " + _vm._s(_vm.$t("table.export")) + " ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: {
            "sort-change": _vm.sortChange,
            "selection-change": _vm.handleSelectionChange
          }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "left",
              label: "Order Status",
              prop: "status"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("strong", [_vm._v("ID:")]),
                    _c(
                      "router-link",
                      { attrs: { to: "/order/" + scope.row.id } },
                      [
                        scope.row.status !== "in cart"
                          ? _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(" " + _vm._s(scope.row.id) + " ")
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    scope.row.status === "in cart"
                      ? _c("div", [_vm._v(" " + _vm._s(scope.row.id) + " ")])
                      : _vm._e(),
                    _c(
                      "p",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              size: "mini",
                              type: _vm.statusColorMap[scope.row.status]
                            }
                          },
                          [_vm._v(" " + _vm._s(scope.row.status) + " ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              size: "mini",
                              type: scope.row.isMobile ? "" : "warning"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.isMobile ? "Mobile" : "Website"
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "250",
              align: "center",
              label: "Products",
              prop: "ordersProduct"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._l(scope.row.ordersProduct, function(product) {
                      return _c(
                        "el-popover",
                        {
                          key: product.id,
                          attrs: {
                            placement: "top-start",
                            width: "400",
                            trigger: "hover"
                          }
                        },
                        [
                          _c("p", [
                            _vm._v("Quantity: " + _vm._s(product.quantity))
                          ]),
                          _c("p", [
                            _vm._v(
                              " Variant: " +
                                _vm._s(
                                  product.productsVariation
                                    ? product.productsVariation.name
                                    : product.product.name
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "p",
                            [
                              _vm._v(" Unit Price: "),
                              _c("inr", {
                                attrs: { number: product.unitPrice }
                              })
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _vm._v(" Sale Price: "),
                              _c("inr", {
                                attrs: { number: product.salePrice }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [_vm._v(" " + _vm._s(product.product.name) + " ")]
                          )
                        ],
                        1
                      )
                    }),
                    _c("br")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "220px",
              align: "left",
              label: "User",
              prop: "userId"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.user
                      ? [
                          scope.row.userOrganizationName
                            ? _c("i", [
                                _c("i", { staticClass: "el-icon-suitcase" }),
                                _c("b", [
                                  _vm._v(
                                    " " + _vm._s(scope.row.userOrganizationName)
                                  )
                                ]),
                                _c("br")
                              ])
                            : _vm._e(),
                          _c("i", { staticClass: "el-icon-user" }),
                          _vm._v(" " + _vm._s(scope.row.user.fullName) + " "),
                          _c("br"),
                          _c("i", { staticClass: "el-icon-mobile-phone" }),
                          _vm._v(
                            " " + _vm._s(scope.row.user.mobileNumber) + " "
                          ),
                          _c("br"),
                          _c("i", { staticClass: "el-icon-message" }),
                          _vm._v(
                            " " + _vm._s(scope.row.user.emailAddress) + " "
                          )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _c("el-table-column", {
            attrs: {
              width: "210px",
              align: "left",
              label: "Grand Total",
              prop: "grandTotal"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._l(
                      ["total", "taxedAmount", "freightCharges", "grandTotal"],
                      function(field) {
                        return _c(
                          "div",
                          { key: field },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.totalColumn[field]) + ":")
                            ]),
                            _c("inr", { attrs: { number: scope.row[field] } })
                          ],
                          1
                        )
                      }
                    ),
                    scope.row.status === "Partial Payment"
                      ? _c("div", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.totalColumn["amountReceived"]) + ":"
                            )
                          ]),
                          _vm._v(
                            " " + _vm._s(scope.row["amountReceived"]) + " "
                          )
                        ])
                      : _vm._e(),
                    scope.row.paymentGateway
                      ? _c("div", [
                          _c("strong", [_vm._v("Payment Gateway:")]),
                          _vm._v(" " + _vm._s(scope.row.paymentGateway) + " ")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "225px",
              align: "center",
              prop: "orderTimestamp",
              label: "Placed On"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderTimestamp
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm._f("moment")(
                                scope.row.orderTimestamp,
                                "MMMM Do YYYY, hh:mm a"
                              ),
                              placement: "top"
                            }
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(
                                    scope.row.orderTimestamp,
                                    "from"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _c("br"),
                    scope.row.deliveryOption
                      ? [
                          _c("div", [
                            _vm._v(
                              "Delivery: " + _vm._s(scope.row.deliveryOption)
                            )
                          ]),
                          scope.row.vrlId
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "text-small text-italic" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.vrl && scope.row.vrl.name
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            : _vm._e(),
                          scope.row.nominatedTransportDetails
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "text-small text-italic" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.nominatedTransportDetails
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            : _vm._e()
                        ]
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm.isSuperAdmin()
            ? _c("el-table-column", {
                attrs: { align: "center", label: "Actions", width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("el-button", {
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.editOrder(scope.row.id)
                              }
                            }
                          }),
                          _c(
                            "router-link",
                            {
                              attrs: { to: "/transaction/list/" + scope.row.id }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    { name: "waves", rawName: "v-waves" }
                                  ],
                                  attrs: {
                                    type: "success",
                                    size: "small",
                                    icon: "inr"
                                  }
                                },
                                [_vm._v(" Transaction ")]
                              )
                            ],
                            1
                          ),
                          scope.row.status === "Partial Payment"
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: "/transaction/add/" + scope.row.id
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        { name: "waves", rawName: "v-waves" }
                                      ],
                                      attrs: {
                                        type: "warning",
                                        size: "small",
                                        icon: "inr"
                                      }
                                    },
                                    [_vm._v(" Add Transaction ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          scope.row.status === "Order Cancelled"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    { name: "waves", rawName: "v-waves" }
                                  ],
                                  attrs: {
                                    type: "warning",
                                    size: "small",
                                    icon: "inr"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.initiateRefund(scope.row)
                                    }
                                  }
                                },
                                [_vm._v(" Refund ")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2888858799
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Payment Refund Process",
            visible: _vm.dialogFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formdata",
              staticClass: "demo-form",
              attrs: {
                model: _vm.formdata,
                "status-icon": "",
                "label-position": "left"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Full Refund", prop: "fullRefund" } },
                [
                  _c("el-switch", {
                    on: {
                      change: function($event) {
                        return _vm.toggleRefund($event)
                      }
                    },
                    model: {
                      value: _vm.fullRefund,
                      callback: function($$v) {
                        _vm.fullRefund = $$v
                      },
                      expression: "fullRefund"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Amount", prop: "refundAmount" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 1,
                      max: _vm.refundAmount * 1,
                      disabled: _vm.fullRefund
                    },
                    model: {
                      value: _vm.formdata.refundAmount,
                      callback: function($$v) {
                        _vm.$set(_vm.formdata, "refundAmount", $$v)
                      },
                      expression: "formdata.refundAmount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Comments", prop: "comments" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.formdata.comments,
                      callback: function($$v) {
                        _vm.$set(_vm.formdata, "comments", $$v)
                      },
                      expression: "formdata.comments"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        },
                        { name: "waves", rawName: "v-waves" }
                      ],
                      attrs: { type: "success" },
                      on: { click: _vm.submitForm }
                    },
                    [_vm._v(" Refund ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }