import { render, staticRenderFns } from "./manage.vue?vue&type=template&id=5700eb87&"
import script from "./manage.vue?vue&type=script&lang=ts&"
export * from "./manage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Vaibhav Innovura\\Desktop\\tbadmin\\tradebridge-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5700eb87')) {
      api.createRecord('5700eb87', component.options)
    } else {
      api.reload('5700eb87', component.options)
    }
    module.hot.accept("./manage.vue?vue&type=template&id=5700eb87&", function () {
      api.rerender('5700eb87', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/brand/manage.vue"
export default component.exports