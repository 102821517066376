var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } },
                [
                  _vm.postForm.user
                    ? _c("div", { staticClass: "grid-content bg-purple" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.postForm.user.fullName))
                        ]),
                        _c(
                          "p",
                          [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  icon: "el-icon-message",
                                  target: "_blank",
                                  href:
                                    "mailto:" + _vm.postForm.user.emailAddress
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.postForm.user.emailAddress) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  icon: "el-icon-phone",
                                  target: "_blank",
                                  href: "tel:" + _vm.postForm.user.mobileNumber
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.postForm.user.mobileNumber) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.postForm.user.broker
                          ? _c(
                              "p",
                              [
                                _c(
                                  "el-tag",
                                  {
                                    attrs: {
                                      type: "info",
                                      effect: "plain",
                                      size: "mini"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.postForm.user.broker.name) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c("el-divider", { staticClass: "hidden-sm-and-up" })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } },
                [
                  _vm.postForm.productId
                    ? [
                        _c("el-image", {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: {
                            src:
                              _vm.BASE_URL +
                              "products-images/img/" +
                              _vm.postForm.productId +
                              "/sm",
                            fit: "fit"
                          }
                        }),
                        _c("h4", [
                          _vm._v(" " + _vm._s(_vm.postForm.product.name) + " ")
                        ]),
                        _c(
                          "p",
                          { staticClass: "text-italic text-small" },
                          [
                            _vm._v(" Bid Price: "),
                            _c("inr", {
                              attrs: { number: _vm.postForm.bidPrice }
                            })
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "text-italic text-small" },
                          [
                            _vm._v(" Current Price: "),
                            _c("inr", {
                              attrs: { number: _vm.postForm.productPrice }
                            })
                          ],
                          1
                        ),
                        _c("p", { staticClass: "text-italic text-small" }, [
                          _vm._v(
                            " Bid Quantity: " +
                              _vm._s(_vm.postForm.bidQuantity) +
                              " "
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "form-block" }, [
        _c(
          "div",
          { staticClass: "source" },
          [
            _c(
              "el-form",
              {
                ref: "postForm",
                staticClass: "demo-form",
                attrs: {
                  model: _vm.postForm,
                  "status-icon": "",
                  rules: _vm.rules,
                  "label-position": "left"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Enabled", prop: "enabled" } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.postForm.enabled,
                        callback: function($$v) {
                          _vm.$set(_vm.postForm, "enabled", $$v)
                        },
                        expression: "postForm.enabled"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Status" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          clearable: "",
                          name: "status",
                          placeholder: "Select"
                        },
                        on: { change: _vm.handleQuoted },
                        model: {
                          value: _vm.postForm.status,
                          callback: function($$v) {
                            _vm.$set(_vm.postForm, "status", $$v)
                          },
                          expression: "postForm.status"
                        }
                      },
                      _vm._l(_vm.statusOptions, function(item) {
                        return _c("el-option", {
                          key: item.key,
                          attrs: { label: item.label, value: item.key }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.postForm.status === "Rejected"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Rejection Reason",
                          prop: "rejectReason"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 2,
                            placeholder: "Enter reason for rejection"
                          },
                          model: {
                            value: _vm.postForm.rejectReason,
                            callback: function($$v) {
                              _vm.$set(_vm.postForm, "rejectReason", $$v)
                            },
                            expression: "postForm.rejectReason"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showQuoted
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "Quoted Price", prop: "quotedPrice" } },
                      [
                        _c("el-input-number", {
                          attrs: {
                            name: "quotedPrice",
                            required: "",
                            placeholder: "Quoted Price",
                            precision: 2,
                            min: +_vm.postForm.bidPrice,
                            max: +_vm.postForm.productPrice
                          },
                          model: {
                            value: _vm.postForm.quotedPrice,
                            callback: function($$v) {
                              _vm.$set(_vm.postForm, "quotedPrice", $$v)
                            },
                            expression: "postForm.quotedPrice"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading"
                          },
                          { name: "waves", rawName: "v-waves" }
                        ],
                        attrs: { type: "success" },
                        on: { click: _vm.submitForm }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        attrs: { type: "reset" },
                        on: { click: _vm.resetForm }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }