"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/toConsumableArray"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _bannerSubMenu = _interopRequireDefault(require("./subMenus/banner-sub-menu"));
var _brokerSubMenu = require("./subMenus/broker-sub-menu");
var _codBasedPincode = _interopRequireDefault(require("./subMenus/cod-based-pincode"));
var _locationSubMenu = require("./subMenus/location-sub-menu");
var _measurementUnitSubMenu = require("./subMenus/measurement-unit-sub-menu");
var _mustTrySubMenu = require("./subMenus/mustTry-sub-menu");
var _officeSubMenu = require("./subMenus/office-sub-menu");
var _questionsSubMenuCopy = _interopRequireDefault(require("./subMenus/questions-sub-menu copy"));
var _taxSubMenu = require("./subMenus/tax-sub-menu");
var _vrlSubMenu = _interopRequireDefault(require("./subMenus/vrl-sub-menu"));
var masterRouter = {
  path: "/masters",
  component: _index.default,
  redirect: "noredirect",
  name: "Masters",
  meta: {
    title: "master.title",
    icon: "star"
  },
  children: [].concat((0, _toConsumableArray2.default)(_bannerSubMenu.default), (0, _toConsumableArray2.default)(_taxSubMenu.taxSubMenu), (0, _toConsumableArray2.default)(_mustTrySubMenu.mustTrySubMenu), (0, _toConsumableArray2.default)(_locationSubMenu.locationSubMenu), (0, _toConsumableArray2.default)(_brokerSubMenu.brokersSubMenu), (0, _toConsumableArray2.default)(_measurementUnitSubMenu.measurementUnitSubMenu), (0, _toConsumableArray2.default)(_officeSubMenu.officeSubMenu), (0, _toConsumableArray2.default)(_vrlSubMenu.default), (0, _toConsumableArray2.default)(_questionsSubMenuCopy.default), (0, _toConsumableArray2.default)(_codBasedPincode.default))
};
var _default = masterRouter;
exports.default = _default;