var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: { width: "50", align: "center", label: "ID", prop: "id" }
          }),
          _c("el-table-column", {
            attrs: {
              width: "220px",
              align: "center",
              label: "Booking Amount Threshold",
              prop: "bookingAmountThreshold"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              align: "center",
              label: "Transaction Percent",
              prop: "transactionPercent"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              label: "Booking Percent",
              prop: "bookingPercent"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "center",
              label: "Bank Information",
              prop: "bankInformation"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              align: "center",
              label: "Expiry Days",
              prop: "expiryDays"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "170px",
              align: "center",
              label: "Cancellation Charges",
              prop: "cancellationCharges"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              align: "center",
              label: "Automated Credit Note Generation Days",
              prop: "automatedCreditNoteGenerationDays"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              align: "center",
              label: "Bid Expiry Hours",
              prop: "bidExpiryHours"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Actions", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      { attrs: { to: "/setting/edit/" + scope.row.id } },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-edit"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }