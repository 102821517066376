"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateWalletTransaction = exports.getWalletTransactions = exports.getWalletTransactionById = exports.deleteWalletTransaction = exports.defaultUserWalletData = exports.createWalletTransaction = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultUserWalletData = {
  id: 0,
  enabled: true,
  amount: undefined,
  action: undefined,
  userId: 0,
  user: {
    id: 0
  },
  orderId: 0,
  order: {
    id: 0
  }
};
exports.defaultUserWalletData = defaultUserWalletData;
var getWalletTransactions = function getWalletTransactions(params) {
  return (0, _request.default)({
    url: '/wallet-transactions',
    method: 'get',
    params: params
  });
};
exports.getWalletTransactions = getWalletTransactions;
var createWalletTransaction = function createWalletTransaction(data) {
  return (0, _request.default)({
    url: '/wallet-transactions',
    method: 'post',
    data: data
  });
};
exports.createWalletTransaction = createWalletTransaction;
var getWalletTransactionById = function getWalletTransactionById(id) {
  return (0, _request.default)({
    url: "/wallet-transactions/".concat(id),
    method: 'get'
  });
};
exports.getWalletTransactionById = getWalletTransactionById;
var updateWalletTransaction = function updateWalletTransaction(id, data) {
  return (0, _request.default)({
    url: "/wallet-transactions/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateWalletTransaction = updateWalletTransaction;
var deleteWalletTransaction = function deleteWalletTransaction(id) {
  return (0, _request.default)({
    url: "/wallet-transactions/".concat(id),
    method: 'delete'
  });
};
exports.deleteWalletTransaction = deleteWalletTransaction;