var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Page", prop: "page" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      name: "page",
                      required: "",
                      filterable: "",
                      "auto-complete": "off"
                    },
                    model: {
                      value: _vm.formData.pageName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "pageName", $$v)
                      },
                      expression: "formData.pageName"
                    }
                  },
                  _vm._l(_vm.pageList, function(page) {
                    return _c("el-option", {
                      key: page.key,
                      attrs: { label: page.value, value: page.key }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Title", prop: "title" } },
              [
                _c("el-input", {
                  attrs: { name: "title", required: "", placeholder: "Title" },
                  model: {
                    value: _vm.formData.title,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "title", $$v)
                    },
                    expression: "formData.title"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Offer Text", prop: "text" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "text",
                    required: "",
                    placeholder: "Offer Text"
                  },
                  model: {
                    value: _vm.formData.text,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "text", $$v)
                    },
                    expression: "formData.text"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Category", prop: "categoryId" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      name: "categoryId",
                      required: "",
                      filterable: "",
                      "auto-complete": "off",
                      placeholder: "Category"
                    },
                    model: {
                      value: _vm.formData.categoryId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "categoryId", $$v)
                      },
                      expression: "formData.categoryId"
                    }
                  },
                  _vm._l(_vm.categories, function(category) {
                    return _c("el-option", {
                      key: category.id,
                      attrs: { label: category.name, value: category.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Image", prop: "image" } },
              [
                _c("file-upload", {
                  attrs: {
                    multiple: false,
                    "file-list": _vm.image,
                    "max-size": 1,
                    url: "/common/upload"
                  },
                  on: { change: _vm.imageUploaded }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Priority", prop: "priority" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "priority",
                    required: "",
                    placeholder: "Priority"
                  },
                  model: {
                    value: _vm.formData.priority,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "priority", $$v)
                    },
                    expression: "formData.priority"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }