"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOutstandings = exports.defaultSellersOutstandingData = exports.createOutstanding = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultSellersOutstandingData = {
  id: 0,
  enabled: true,
  sellerId: undefined,
  orderId: undefined,
  ordersSellerId: undefined,
  amount: 0,
  action: undefined,
  comments: undefined
};
exports.defaultSellersOutstandingData = defaultSellersOutstandingData;
var getOutstandings = function getOutstandings(params) {
  return (0, _request.default)({
    url: '/sellers-outstandings',
    method: 'get',
    params: params
  });
};
exports.getOutstandings = getOutstandings;
var createOutstanding = function createOutstanding(data) {
  return (0, _request.default)({
    url: '/sellers-outstandings/',
    method: 'post',
    data: data
  });
};
exports.createOutstanding = createOutstanding;