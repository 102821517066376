var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Pincode Name", prop: "pincodeName" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "pincodeName",
                    required: "",
                    placeholder: "Pincode Name"
                  },
                  model: {
                    value: _vm.formData.pincodeName,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "pincodeName", $$v)
                    },
                    expression: "formData.pincodeName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Pincode", prop: "pincode" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "pincode",
                    required: "",
                    placeholder: "Pincode",
                    minlength: 6,
                    maxlength: 6
                  },
                  model: {
                    value: _vm.formData.pincode,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "pincode", $$v)
                    },
                    expression: "formData.pincode"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Freight Charges", prop: "freightCharges" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "freightCharges",
                    required: "",
                    min: 0,
                    precision: 2,
                    step: 1
                  },
                  model: {
                    value: _vm.formData.freightCharges,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "freightCharges", $$v)
                    },
                    expression: "formData.freightCharges"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }