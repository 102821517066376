"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCategory = exports.getCategoryById = exports.getCategoriesTree = exports.getCategories = exports.fileTransferHeaders = exports.deleteCategory = exports.defaultCategoryData = exports.createCategory = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultCategoryData = {
  id: 0,
  enabled: true,
  name: "",
  icon: "",
  image: "",
  description: "",
  priority: 0,
  featured: true,
  parent: null,
  bgcolor: null,
  amountStartingFrom: null
};
exports.defaultCategoryData = defaultCategoryData;
var getCategories = function getCategories(params) {
  return (0, _request.default)({
    url: "/categories",
    method: "get",
    params: params
  });
};
exports.getCategories = getCategories;
var getCategoriesTree = function getCategoriesTree() {
  return (0, _request.default)({
    url: "/categories/tree",
    method: "get"
  });
};
exports.getCategoriesTree = getCategoriesTree;
var getCategoryById = function getCategoryById(id) {
  return (0, _request.default)({
    url: "/categories/".concat(id),
    method: "get"
  });
};
exports.getCategoryById = getCategoryById;
var updateCategory = function updateCategory(id, data) {
  return (0, _request.default)({
    url: "/categories/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateCategory = updateCategory;
var deleteCategory = function deleteCategory(id) {
  return (0, _request.default)({
    url: "/categories/".concat(id),
    method: "delete"
  });
};
exports.deleteCategory = deleteCategory;
var createCategory = function createCategory(data) {
  return (0, _request.default)({
    url: "/categories",
    method: "post",
    data: data
  });
};
exports.createCategory = createCategory;