"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateVRL = exports.getVRLs = exports.getVRLById = exports.getVRLByFromStateId = exports.deleteVRL = exports.defaultVRLData = exports.createVRL = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultVRLData = {
  id: 0,
  enabled: true,
  fromStateId: undefined,
  toStateId: undefined,
  warehouseCharges: undefined,
  homeDeliveryCharges: undefined
};
exports.defaultVRLData = defaultVRLData;
var getVRLs = function getVRLs(params) {
  return (0, _request.default)({
    url: '/vrl-delivery-locations',
    method: 'get',
    params: params
  });
};
exports.getVRLs = getVRLs;
var getVRLById = function getVRLById(id) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'get'
  });
};
exports.getVRLById = getVRLById;
var getVRLByFromStateId = function getVRLByFromStateId(stateId) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations?filter=fromStateId||$eq||".concat(stateId, "&or=toStateId||$eq||").concat(stateId),
    method: 'get'
  });
};
exports.getVRLByFromStateId = getVRLByFromStateId;
var updateVRL = function updateVRL(id, data) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateVRL = updateVRL;
var deleteVRL = function deleteVRL(id) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'delete'
  });
};
exports.deleteVRL = deleteVRL;
var createVRL = function createVRL(data) {
  return (0, _request.default)({
    url: '/vrl-delivery-locations/',
    method: 'post',
    data: data
  });
};
exports.createVRL = createVRL;