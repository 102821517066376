var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [_c("span", [_vm._v("Product Info")])]
          ),
          _vm._l(_vm.data.ordersProduct, function(p) {
            return _c(
              "div",
              {
                key: p,
                staticClass: "text item",
                staticStyle: { padding: "5px" }
              },
              [
                _c("p", [
                  _c("b", [_vm._v("Product Name: ")]),
                  _vm._v(_vm._s(p.product.name))
                ]),
                _c(
                  "p",
                  [
                    _c("b", [_vm._v(" Price:")]),
                    _c("inr", { attrs: { number: p.unitPrice } })
                  ],
                  1
                ),
                _c(
                  "p",
                  [
                    _c("b", [_vm._v(" Sale Price:")]),
                    _c("inr", { attrs: { number: p.salePrice } })
                  ],
                  1
                ),
                _c(
                  "p",
                  [
                    _c("b", [_vm._v("Taxed Amount:")]),
                    _c("inr", { attrs: { number: p.taxedAmount } })
                  ],
                  1
                ),
                _c("p", [
                  _c("b", [_vm._v(" Rate:")]),
                  _vm._v(" " + _vm._s(p.taxRate) + "%")
                ])
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }