var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header"
              },
              [
                _c("span", [_vm._v("VRL State wise charges")]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "Select State"
                    },
                    on: {
                      change: _vm.fetchVRL,
                      clear: _vm.stateSelectionCleared
                    },
                    model: {
                      value: _vm.selectedStateId,
                      callback: function($$v) {
                        _vm.selectedStateId = $$v
                      },
                      expression: "selectedStateId"
                    }
                  },
                  _vm._l(_vm.states, function(state) {
                    return _c("el-option", {
                      key: state.id,
                      attrs: { label: state.name, value: state.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm.vrls.length
              ? [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.vrls, border: "", height: "500" }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "toStateName",
                          label: "State",
                          width: "200",
                          fixed: ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "id", label: "ID", width: "80" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "warehouseCharges",
                          label: "Warehouse Charges"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input-number", {
                                    attrs: {
                                      precision: 2,
                                      step: 1,
                                      min: 0,
                                      max: 10000,
                                      size: "mini",
                                      disabled: scope.row.saving
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.saveVRL(scope.row)
                                      }
                                    },
                                    model: {
                                      value: scope.row.warehouseCharges,
                                      callback: function($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "warehouseCharges",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.warehouseCharges"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3533477489
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "homeDeliveryCharges",
                          label: "Home Delivery Charges"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input-number", {
                                    attrs: {
                                      precision: 2,
                                      step: 1,
                                      min: 0,
                                      max: 10000,
                                      size: "mini",
                                      disabled: scope.row.saving
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.saveVRL(scope.row)
                                      }
                                    },
                                    model: {
                                      value: scope.row.homeDeliveryCharges,
                                      callback: function($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "homeDeliveryCharges",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scope.row.homeDeliveryCharges"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          644857639
                        )
                      })
                    ],
                    1
                  )
                ]
              : [
                  _c("el-empty", {
                    attrs: { description: "Kindly make a selection" }
                  })
                ]
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }