"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUsersLogs = exports.getUsersLogById = exports.getCountByUser = exports.defaultUsersLogData = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultUsersLogData = {
  id: 0,
  enabled: true,
  userId: 0,
  user: {
    id: undefined
  }
};
exports.defaultUsersLogData = defaultUsersLogData;
var getUsersLogs = function getUsersLogs(params) {
  return (0, _request.default)({
    url: '/users-logs',
    method: 'get',
    params: params
  });
};
exports.getUsersLogs = getUsersLogs;
var getUsersLogById = function getUsersLogById(id) {
  return (0, _request.default)({
    url: "/users-logs/".concat(id),
    method: 'get'
  });
};
exports.getUsersLogById = getUsersLogById;
var getCountByUser = function getCountByUser(data) {
  return (0, _request.default)({
    url: '/users-logs/list',
    method: 'post',
    data: data
  });
};
exports.getCountByUser = getCountByUser;