var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header"
              },
              [_c("span", [_vm._v("COD Based Pincode")])]
            ),
            _c(
              "el-form",
              { ref: "formData" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "file" },
                  [
                    _c("file-upload", {
                      attrs: {
                        multiple: false,
                        "max-size": 1,
                        "list-type": "text",
                        "file-list": _vm.icon,
                        url: "/common/upload",
                        accept: ".csv",
                        type: ".csv"
                      },
                      on: { change: _vm.uploadFile }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading"
                          },
                          { name: "waves", rawName: "v-waves" }
                        ],
                        attrs: { type: "success" },
                        on: { click: _vm.submitForm }
                      },
                      [_vm._v(" Click to upload ")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticClass: "button-file",
                        attrs: {
                          type: "primary",
                          href:
                            "https://ashapura.s3.ap-south-1.amazonaws.com/api/common/a01f69e7-fb5a-45b1-aa3f-bb7e0ccf6202.csv",
                          target: "_blank"
                        }
                      },
                      [_vm._v(" View Sample File ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }