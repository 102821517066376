"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrganization = exports.getOrganizations = exports.getOrganizationById = exports.fileTransferHeaders = exports.deleteOrganization = exports.defaultOrganizationData = exports.createOrganization = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultOrganizationData = {
  id: 0,
  enabled: true,
  name: '',
  gstNumber: '',
  panNumber: '',
  fssaiNumber: ''
};
exports.defaultOrganizationData = defaultOrganizationData;
var getOrganizations = function getOrganizations(params) {
  return (0, _request.default)({
    url: '/Organizations',
    method: 'get',
    params: params
  });
};
exports.getOrganizations = getOrganizations;
var getOrganizationById = function getOrganizationById(id) {
  return (0, _request.default)({
    url: "/Organizations/".concat(id),
    method: 'get'
  });
};
exports.getOrganizationById = getOrganizationById;
var updateOrganization = function updateOrganization(id, data) {
  return (0, _request.default)({
    url: "/Organizations/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateOrganization = updateOrganization;
var deleteOrganization = function deleteOrganization(id) {
  return (0, _request.default)({
    url: "/Organizations/".concat(id),
    method: 'delete'
  });
};
exports.deleteOrganization = deleteOrganization;
var createOrganization = function createOrganization(data) {
  return (0, _request.default)({
    url: '/Organizations/',
    method: 'post',
    data: data
  });
};
exports.createOrganization = createOrganization;