"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSubscription = exports.getSubscriptions = exports.getSubscriptionById = exports.deleteSubscription = exports.defaultSubscriptionData = exports.createSubscription = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultSubscriptionData = {
  id: 0,
  enabled: true,
  referenceNumber: undefined,
  planName: undefined,
  planValidity: undefined,
  planPrice: 0,
  taxRate: 0,
  taxedAmount: 0,
  status: null,
  validFrom: null,
  validTill: null,
  paymentGateway: null,
  userId: null,
  user: {
    id: undefined
  },
  planId: null,
  plan: {
    id: undefined
  }
};
exports.defaultSubscriptionData = defaultSubscriptionData;
var getSubscriptions = function getSubscriptions(params) {
  return (0, _request.default)({
    url: '/subscriptions',
    method: 'get',
    params: params
  });
};
exports.getSubscriptions = getSubscriptions;
var getSubscriptionById = function getSubscriptionById(id) {
  return (0, _request.default)({
    url: "/subscriptions/".concat(id),
    method: 'get'
  });
};
exports.getSubscriptionById = getSubscriptionById;
var updateSubscription = function updateSubscription(id, data) {
  return (0, _request.default)({
    url: "/subscriptions/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateSubscription = updateSubscription;
var deleteSubscription = function deleteSubscription(id) {
  return (0, _request.default)({
    url: "/subscriptions/".concat(id),
    method: 'delete'
  });
};
exports.deleteSubscription = deleteSubscription;
var createSubscription = function createSubscription(data) {
  return (0, _request.default)({
    url: '/subscriptions/',
    method: 'post',
    data: data
  });
};
exports.createSubscription = createSubscription;