import { render, staticRenderFns } from "./BrokerDetails.vue?vue&type=template&id=d086cfc8&scoped=true&"
import script from "./BrokerDetails.vue?vue&type=script&lang=ts&"
export * from "./BrokerDetails.vue?vue&type=script&lang=ts&"
import style0 from "./BrokerDetails.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./BrokerDetails.vue?vue&type=style&index=1&id=d086cfc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d086cfc8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Vaibhav Innovura\\Desktop\\tbadmin\\tradebridge-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d086cfc8')) {
      api.createRecord('d086cfc8', component.options)
    } else {
      api.reload('d086cfc8', component.options)
    }
    module.hot.accept("./BrokerDetails.vue?vue&type=template&id=d086cfc8&scoped=true&", function () {
      api.rerender('d086cfc8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/broker/components/BrokerDetails.vue"
export default component.exports