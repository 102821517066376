var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    [
      _c(
        "el-form-item",
        { attrs: { label: "Name" } },
        [
          _c("el-input", {
            model: {
              value: _vm.formData.fullName,
              callback: function($$v) {
                _vm.$set(
                  _vm.formData,
                  "fullName",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.fullName"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Email" } },
        [
          _c("el-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.formData.emailAddress,
              callback: function($$v) {
                _vm.$set(
                  _vm.formData,
                  "emailAddress",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.emailAddress"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Mobile" } },
        [
          _c("el-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.formData.mobileNumber,
              callback: function($$v) {
                _vm.$set(
                  _vm.formData,
                  "mobileNumber",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.mobileNumber"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Avatar", prop: "formData.avatar" } },
        [
          _c("file-upload", {
            attrs: { "file-list": _vm.avatar },
            on: { change: _vm.fileUploaded }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v(" Update ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }