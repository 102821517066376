"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadPincodeFile = exports.updatePincode = exports.getPincodes = exports.getPincodeById = exports.fileTransferHeaders = exports.deletePincode = exports.defaultPincodeData = exports.createPincode = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultPincodeData = {
  id: 0,
  enabled: true,
  allowCod: false,
  countryId: undefined,
  country: {
    id: null
  },
  stateId: undefined,
  state: {
    id: null
  },
  cityId: undefined,
  city: {
    id: null
  },
  pincode: "",
  pincodeName: ""
};
exports.defaultPincodeData = defaultPincodeData;
var getPincodes = function getPincodes(params) {
  return (0, _request.default)({
    url: "/pincodes",
    method: "get",
    params: params
  });
};
exports.getPincodes = getPincodes;
var uploadPincodeFile = function uploadPincodeFile(data) {
  return (0, _request.default)({
    url: "/pincodes/updateCod",
    method: "post",
    data: data
  });
};
exports.uploadPincodeFile = uploadPincodeFile;
var getPincodeById = function getPincodeById(id) {
  return (0, _request.default)({
    url: "/pincodes/".concat(id),
    method: "get"
  });
};
exports.getPincodeById = getPincodeById;
var updatePincode = function updatePincode(id, data) {
  return (0, _request.default)({
    url: "/pincodes/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updatePincode = updatePincode;
var deletePincode = function deletePincode(id) {
  return (0, _request.default)({
    url: "/pincodes/".concat(id),
    method: "delete"
  });
};
exports.deletePincode = deletePincode;
var createPincode = function createPincode(data) {
  return (0, _request.default)({
    url: "/pincodes/",
    method: "post",
    data: data
  });
};
exports.createPincode = createPincode;