"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadProductImages = exports.updateProductVariations = exports.updateProductInventories = exports.updateProductImages = exports.updateProductCategories = exports.updateProductAttributes = exports.updateProduct = exports.productCount = exports.getProducts = exports.getProductById = exports.deleteProductVariation = exports.deleteProductImages = exports.deleteProduct = exports.defaultProductData = exports.createProduct = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultProductData = {
  id: 0,
  enabled: true,
  name: null,
  sku: "",
  productType: "simple",
  status: "Pending",
  priority: null,
  measurementUnitId: null,
  multiplier: 1,
  featured: false,
  isFeaturedInCategory: false,
  isVerified: false,
  isBestSeller: false,
  isfreightAllowed: true,
  suppliedBy: "",
  tags: null,
  shortDescription: null,
  description: null,
  isTaxable: true,
  taxId: null,
  hsnCode: null,
  manageStock: false,
  stockStatus: "In Stock",
  countryOfOrigin: undefined,
  packingSize: undefined,
  packingType: undefined,
  moq: undefined,
  shelfLife: undefined,
  storageCondition: undefined,
  color: undefined,
  grade: undefined,
  dimensions: undefined,
  mrp: undefined,
  moisturePercent: undefined,
  availableInAllCities: true,
  validFrom: undefined,
  validTill: undefined,
  tax: {
    id: undefined
  },
  measurementUnit: {
    id: undefined
  },
  seller: {
    id: null
  },
  unitOfMeasurementId: null,
  category: [],
  attribute: [],
  cities: [],
  productsImage: [],
  productsVariation: [],
  sellersProductsVariation: []
};
exports.defaultProductData = defaultProductData;
var getProducts = function getProducts(params) {
  return (0, _request.default)({
    url: "/products",
    method: "get",
    params: params
  });
};
exports.getProducts = getProducts;
var getProductById = function getProductById(id) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: "get"
  });
};
exports.getProductById = getProductById;
var updateProduct = function updateProduct(id, data) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateProduct = updateProduct;
var deleteProduct = function deleteProduct(id) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: "delete"
  });
};
exports.deleteProduct = deleteProduct;
var createProduct = function createProduct(data) {
  return (0, _request.default)({
    url: "/products/",
    method: "post",
    data: data
  });
};
exports.createProduct = createProduct;
var productCount = function productCount(params) {
  return (0, _request.default)({
    url: "/products/count",
    method: "get",
    params: params
  });
};
exports.productCount = productCount;
var updateProductCategories = function updateProductCategories(id, data) {
  return (0, _request.default)({
    url: "/products/categories/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateProductCategories = updateProductCategories;
var updateProductAttributes = function updateProductAttributes(id, data) {
  return (0, _request.default)({
    url: "/products/attributes/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateProductAttributes = updateProductAttributes;
var updateProductVariations = function updateProductVariations(id, data) {
  return (0, _request.default)({
    url: "/products-variations/manage",
    method: "post",
    data: data
  });
};
exports.updateProductVariations = updateProductVariations;
var deleteProductVariation = function deleteProductVariation(id) {
  return (0, _request.default)({
    url: "/products-variations/".concat(id),
    method: "delete"
  });
};
exports.deleteProductVariation = deleteProductVariation;
var updateProductImages = function updateProductImages(id, data) {
  return (0, _request.default)({
    url: "/products/images/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateProductImages = updateProductImages;
var deleteProductImages = function deleteProductImages(id) {
  return (0, _request.default)({
    url: "/products-images/".concat(id),
    method: "delete"
  });
};
exports.deleteProductImages = deleteProductImages;
var updateProductInventories = function updateProductInventories(id, data) {
  return (0, _request.default)({
    url: "/products/inventories/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateProductInventories = updateProductInventories;
var uploadProductImages = function uploadProductImages(data) {
  return (0, _request.default)({
    url: "/products/images",
    method: "post",
    data: data
  });
};
exports.uploadProductImages = uploadProductImages;