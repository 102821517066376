"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.array.for-each");
require("core-js/modules/es.array.map");
require("core-js/modules/es.array-buffer.constructor");
require("core-js/modules/es.array-buffer.slice");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.regexp.to-string");
require("core-js/modules/es.typed-array.uint8-array");
require("core-js/modules/es.typed-array.copy-within");
require("core-js/modules/es.typed-array.every");
require("core-js/modules/es.typed-array.fill");
require("core-js/modules/es.typed-array.filter");
require("core-js/modules/es.typed-array.find");
require("core-js/modules/es.typed-array.find-index");
require("core-js/modules/es.typed-array.for-each");
require("core-js/modules/es.typed-array.includes");
require("core-js/modules/es.typed-array.index-of");
require("core-js/modules/es.typed-array.iterator");
require("core-js/modules/es.typed-array.join");
require("core-js/modules/es.typed-array.last-index-of");
require("core-js/modules/es.typed-array.map");
require("core-js/modules/es.typed-array.reduce");
require("core-js/modules/es.typed-array.reduce-right");
require("core-js/modules/es.typed-array.reverse");
require("core-js/modules/es.typed-array.set");
require("core-js/modules/es.typed-array.slice");
require("core-js/modules/es.typed-array.some");
require("core-js/modules/es.typed-array.sort");
require("core-js/modules/es.typed-array.subarray");
require("core-js/modules/es.typed-array.to-locale-string");
require("core-js/modules/es.typed-array.to-string");
require("core-js/modules/web.dom-collections.for-each");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportTable2Excel = exports.exportJson2Excel = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/toConsumableArray"));
var _classCallCheck2 = _interopRequireDefault(require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/classCallCheck"));
var _fileSaver = require("file-saver");
var _xlsx = _interopRequireDefault(require("xlsx"));
var Workbook = function Workbook() {
  (0, _classCallCheck2.default)(this, Workbook);
  this.SheetNames = [];
  this.Sheets = {};
};
var generateArray = function generateArray(table) {
  var out = [];
  var rows = table.querySelectorAll('tr');
  var ranges = [];
  var _loop = function _loop(R) {
    var outRow = [];
    var row = rows[R];
    var columns = row.querySelectorAll('td');
    for (var C = 0; C < columns.length; ++C) {
      var cell = columns[C];
      var colspanStr = cell.getAttribute('colspan');
      var rowspanStr = cell.getAttribute('rowspan');
      var colspan = void 0;
      var rowspan = void 0;
      if (colspanStr) {
        colspan = parseInt(colspanStr);
      }
      if (rowspanStr) {
        rowspan = parseInt(rowspanStr);
      }
      var cellValue = cell.innerText;
      // Skip ranges
      ranges.forEach(function (range) {
        if (R >= range.s.r && R <= range.e.r && outRow.length >= range.s.c && outRow.length <= range.e.c) {
          for (var i = 0; i <= range.e.c - range.s.c; ++i) {
            outRow.push(null);
          }
        }
      });
      // Handle Row Span
      if (rowspan || colspan) {
        rowspan = rowspan || 1;
        colspan = colspan || 1;
        ranges.push({
          s: {
            r: R,
            c: outRow.length
          },
          e: {
            r: R + rowspan - 1,
            c: outRow.length + colspan - 1
          }
        });
      }
      // Handle Value
      outRow.push(cellValue !== '' ? cellValue : null);
      // Handle Colspan
      if (colspan) {
        for (var k = 0; k < colspan - 1; ++k) {
          outRow.push(null);
        }
      }
    }
    out.push(outRow);
  };
  for (var R = 0; R < rows.length; ++R) {
    _loop(R);
  }
  return [out, ranges];
};
var datenum = function datenum(date) {
  return (+date - +new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
};
var sheetFromDataArray = function sheetFromDataArray(data) {
  var ws = {};
  var range = {
    s: {
      c: 10000000,
      r: 10000000
    },
    e: {
      c: 0,
      r: 0
    }
  };
  for (var R = 0; R !== data.length; ++R) {
    for (var C = 0; C !== data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      var cell = {
        v: data[R][C],
        t: '',
        z: ''
      };
      if (cell.v == null) continue;
      var cellRef = _xlsx.default.utils.encode_cell({
        c: C,
        r: R
      });
      if (typeof cell.v === 'number') cell.t = 'n';else if (typeof cell.v === 'boolean') cell.t = 'b';else if (cell.v instanceof Date) {
        cell.t = 'n';
        cell.z = _xlsx.default.SSF.get_table()[14];
        cell.v = datenum(cell.v);
      } else cell.t = 's';
      ws[cellRef] = cell;
    }
  }
  if (range.s.c < 10000000) {
    ws['!ref'] = _xlsx.default.utils.encode_range(range);
  }
  return ws;
};
var s2ab = function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
};
var exportTable2Excel = function exportTable2Excel(id) {
  var theTable = document.getElementById(id);
  if (theTable) {
    var oo = generateArray(theTable);
    var ranges = oo[1];
    /* original data */
    var data = oo[0];
    var wsName = 'SheetJS';
    var wb = new Workbook();
    var ws = sheetFromDataArray(data);
    /* add ranges to worksheet */
    // ws['!cols'] = ['apple', 'banan']
    ws['!merges'] = ranges;
    /* add worksheet to workbook */
    wb.SheetNames.push(wsName);
    wb.Sheets[wsName] = ws;
    var wbout = _xlsx.default.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary'
    });
    (0, _fileSaver.saveAs)(new Blob([s2ab(wbout)], {
      type: 'application/octet-stream'
    }), 'test.xlsx');
  }
};
exports.exportTable2Excel = exportTable2Excel;
var exportJson2Excel = function exportJson2Excel(header, data) {
  var filename = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'excel-list';
  var multiHeader = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var merges = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
  var autoWidth = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true;
  var bookType = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 'xlsx';
  data = (0, _toConsumableArray2.default)(data);
  data.unshift(header);
  for (var i = multiHeader.length - 1; i > -1; i--) {
    data.unshift(multiHeader[i]);
  }
  var wsName = 'SheetJS';
  var wb = new Workbook();
  var ws = sheetFromDataArray(data);
  if (merges.length > 0) {
    if (!ws['!merges']) {
      ws['!merges'] = [];
    }
    merges.forEach(function (item) {
      ws['!merges'].push(_xlsx.default.utils.decode_range(item));
    });
  }
  if (autoWidth) {
    // 设置worksheet每列的最大宽度
    var colWidth = data.map(function (row) {
      return row.map(function (val) {
        // 先判断是否为 null/undefined
        if (val == null) {
          return {
            wch: 10
          };
          // 再判断是否为中文
        } else if (val.toString().charCodeAt(0) > 255) {
          return {
            wch: val.toString().length * 2
          };
        } else {
          return {
            wch: val.toString().length
          };
        }
      });
    });
    // 以第一行为初始值
    var result = colWidth[0];
    for (var _i = 1; _i < colWidth.length; _i++) {
      for (var j = 0; j < colWidth[_i].length; j++) {
        if (result[j].wch < colWidth[_i][j].wch) {
          result[j].wch = colWidth[_i][j].wch;
        }
      }
    }
    ws['!cols'] = result;
  }
  // Add worksheet to workbook
  wb.SheetNames.push(wsName);
  wb.Sheets[wsName] = ws;
  var wbout = _xlsx.default.write(wb, {
    bookType: bookType,
    bookSST: false,
    type: 'binary'
  });
  (0, _fileSaver.saveAs)(new Blob([s2ab(wbout)], {
    type: 'application/octet-stream'
  }), "".concat(filename, ".").concat(bookType));
};
exports.exportJson2Excel = exportJson2Excel;