"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSellerBids = exports.getSellerBidById = exports.defaultSellersBidData = exports.bidsCount = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultSellersBidData = {
  id: 0,
  enabled: true,
  sellerId: undefined,
  sellersProductsVariationId: undefined,
  bidId: undefined,
  status: '',
  rejectReason: '',
  approvedTimestamp: null,
  rejectedTimestamp: null,
  expiredTimestamp: null,
  seller: {
    id: null
  },
  bid: {
    id: null
  }
};
exports.defaultSellersBidData = defaultSellersBidData;
var getSellerBids = function getSellerBids(params) {
  return (0, _request.default)({
    url: '/sellers-bids',
    method: 'get',
    params: params
  });
};
exports.getSellerBids = getSellerBids;
var getSellerBidById = function getSellerBidById(id) {
  return (0, _request.default)({
    url: "/sellers-bids/".concat(id),
    method: 'get'
  });
};
exports.getSellerBidById = getSellerBidById;
var bidsCount = function bidsCount(params) {
  return (0, _request.default)({
    url: '/bids/count',
    method: 'get',
    params: params
  });
};
exports.bidsCount = bidsCount;