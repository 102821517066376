var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-width": "200px",
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: { label: "Product", prop: "sellersProductsVariationId" }
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      loading: _vm.productListLoading,
                      clearable: "",
                      filterable: "",
                      remote: "",
                      "remote-method": _vm.getProductList,
                      placeholder: "Search Product"
                    },
                    model: {
                      value: _vm.formData.sellersProductsVariationId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formData,
                          "sellersProductsVariationId",
                          $$v
                        )
                      },
                      expression: "formData.sellersProductsVariationId"
                    }
                  },
                  _vm._l(_vm.productList, function(item) {
                    return _c(
                      "el-option",
                      {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      },
                      [
                        _c("span", { staticStyle: { float: "left" } }, [
                          _vm._v(_vm._s(item.name))
                        ]),
                        _c(
                          "i",
                          {
                            staticStyle: {
                              float: "right",
                              color: "#8492a6",
                              "font-size": "13px"
                            }
                          },
                          [_vm._v(_vm._s(item.seller.fullName))]
                        )
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "City", prop: "cityId" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      remote: "",
                      "remote-method": _vm.getCityList,
                      loading: _vm.cityListLoading,
                      placeholder: "Search City"
                    },
                    model: {
                      value: _vm.formData.cityId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "cityId", $$v)
                      },
                      expression: "formData.cityId"
                    }
                  },
                  _vm._l(_vm.cityList, function(city) {
                    return _c("el-option", {
                      key: city.id,
                      attrs: { label: city.name, value: city.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Price", prop: "unitPrice" } },
              [
                _c("el-input-number", {
                  attrs: { precision: 2, min: 1 },
                  model: {
                    value: _vm.formData.unitPrice,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "unitPrice", $$v)
                    },
                    expression: "formData.unitPrice"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }