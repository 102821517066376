var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "formData",
      staticClass: "demo-form",
      attrs: {
        model: _vm.formData,
        "status-icon": "",
        rules: _vm.rules,
        "label-position": "left"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Enabled", prop: "enabled" } },
        [
          _c("el-switch", {
            attrs: { name: "enabled" },
            model: {
              value: _vm.formData.enabled,
              callback: function($$v) {
                _vm.$set(_vm.formData, "enabled", $$v)
              },
              expression: "formData.enabled"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Featured", prop: "featured" } },
        [
          _c("el-switch", {
            attrs: { name: "featured" },
            model: {
              value: _vm.formData.featured,
              callback: function($$v) {
                _vm.$set(_vm.formData, "featured", $$v)
              },
              expression: "formData.featured"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "Is Featured In Category",
            prop: "isFeaturedInCategory"
          }
        },
        [
          _c("el-switch", {
            attrs: { name: "isFeaturedInCategory" },
            model: {
              value: _vm.formData.isFeaturedInCategory,
              callback: function($$v) {
                _vm.$set(_vm.formData, "isFeaturedInCategory", $$v)
              },
              expression: "formData.isFeaturedInCategory"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "Available In All Cities",
            prop: "availableInAllCities"
          }
        },
        [
          _c("el-switch", {
            attrs: { name: "availableInAllCities" },
            model: {
              value: _vm.formData.availableInAllCities,
              callback: function($$v) {
                _vm.$set(_vm.formData, "availableInAllCities", $$v)
              },
              expression: "formData.availableInAllCities"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Seasonal Product", prop: "isSeasonalProduct" } },
        [
          _c("el-switch", {
            attrs: { name: "isSeasonalProduct" },
            model: {
              value: _vm.formData.isSeasonalProduct,
              callback: function($$v) {
                _vm.$set(_vm.formData, "isSeasonalProduct", $$v)
              },
              expression: "formData.isSeasonalProduct"
            }
          })
        ],
        1
      ),
      _vm.formData.isSeasonalProduct
        ? _c(
            "el-form-item",
            {
              attrs: { label: "Duration of Seasonal Product", prop: "validity" }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "start-placeholder": "Start date",
                  "end-placeholder": "End date",
                  "default-time": ["00:00:00", "23:59:59"]
                },
                model: {
                  value: _vm.validity,
                  callback: function($$v) {
                    _vm.validity = $$v
                  },
                  expression: "validity"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.formData.availableInAllCities
        ? _c(
            "el-form-item",
            { attrs: { label: "Cities", prop: "cities" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "Type city name",
                    name: "cities",
                    multiple: "",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.fetchCities
                  },
                  model: {
                    value: _vm.selectedCities,
                    callback: function($$v) {
                      _vm.selectedCities = $$v
                    },
                    expression: "selectedCities"
                  }
                },
                _vm._l(_vm.cityList, function(city) {
                  return _c("el-option", {
                    key: city.id,
                    attrs: { label: city.name, value: city.id }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "Status", prop: "status" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "Select", name: "status", required: "" },
              on: { change: _vm.emitFormData },
              model: {
                value: _vm.formData.status,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "status", $$v)
                },
                expression: "formData.status"
              }
            },
            [
              _c("el-option", {
                attrs: { label: "Approve", value: "Approved" }
              }),
              _c("el-option", { attrs: { label: "Reject", value: "Rejected" } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Seller", prop: "seller" } },
        [
          _c("el-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.formData.seller.fullName,
              callback: function($$v) {
                _vm.$set(_vm.formData.seller, "fullName", $$v)
              },
              expression: "formData.seller.fullName"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Name", prop: "name" } },
        [
          _c("el-input", {
            attrs: {
              name: "name",
              required: "",
              placeholder: "Name",
              maxlength: "150",
              minlength: "3",
              "show-word-limit": ""
            },
            model: {
              value: _vm.formData.name,
              callback: function($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: { label: "Unit of Measurement", prop: "unitOfMeasurementId" }
        },
        [
          _c(
            "el-select",
            {
              attrs: {
                placeholder: "Select",
                name: "unitOfMeasurementId",
                required: ""
              },
              model: {
                value: _vm.formData.unitOfMeasurementId,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "unitOfMeasurementId", $$v)
                },
                expression: "formData.unitOfMeasurementId"
              }
            },
            _vm._l(_vm.unitOfMeasurements, function(unit) {
              return _c("el-option", {
                key: unit.id,
                attrs: {
                  label: unit.name + " (" + unit.code + ")",
                  value: unit.id
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Multiplier", prop: "multiplier" } },
        [
          _c("el-input-number", {
            attrs: {
              name: "multiplier",
              placeholder: "Multiplier",
              "controls-position": "right",
              min: 1,
              max: 30000,
              precision: 2
            },
            model: {
              value: _vm.formData.multiplier,
              callback: function($$v) {
                _vm.$set(_vm.formData, "multiplier", $$v)
              },
              expression: "formData.multiplier"
            }
          }),
          _c("help-text", {
            attrs: {
              content:
                "increases/decreases product quantity by factor of multiplier"
            }
          })
        ],
        1
      ),
      _vm.formData.productType === "simple" &&
      _vm.formData.productsVariation.length
        ? [
            _c(
              "el-form-item",
              { attrs: { label: "Unit Price" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "unitPrice",
                    size: "mini",
                    min: 1,
                    disabled: _vm.formData.sellerId > 0 ? true : false
                  },
                  model: {
                    value: _vm.formData.productsVariation[0].unitPrice,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.formData.productsVariation[0],
                        "unitPrice",
                        $$v
                      )
                    },
                    expression: "formData.productsVariation[0].unitPrice"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Quantity" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "stockQuantity",
                    size: "mini",
                    min: 1,
                    disabled: _vm.formData.sellerId > 0 ? true : false
                  },
                  model: {
                    value: _vm.formData.productsVariation[0].stockQuantity,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.formData.productsVariation[0],
                        "stockQuantity",
                        $$v
                      )
                    },
                    expression: "formData.productsVariation[0].stockQuantity"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "SKU" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "sku",
                    placeholder: "SKU",
                    maxlength: "30",
                    minlength: "3"
                  },
                  model: {
                    value: _vm.formData.productsVariation[0].sku,
                    callback: function($$v) {
                      _vm.$set(_vm.formData.productsVariation[0], "sku", $$v)
                    },
                    expression: "formData.productsVariation[0].sku"
                  }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "Is Freight Allowed?", prop: "isfreightAllowed" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.formData.isfreightAllowed,
              callback: function($$v) {
                _vm.$set(_vm.formData, "isfreightAllowed", $$v)
              },
              expression: "formData.isfreightAllowed"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Taxable", prop: "isTaxable" } },
        [
          _c("el-switch", {
            attrs: {
              "inactive-text": "Tax is inclusive",
              "active-text": "Taxes extra"
            },
            model: {
              value: _vm.formData.isTaxable,
              callback: function($$v) {
                _vm.$set(_vm.formData, "isTaxable", $$v)
              },
              expression: "formData.isTaxable"
            }
          })
        ],
        1
      ),
      _vm.formData.isTaxable
        ? _c(
            "el-form-item",
            { attrs: { label: "Tax", prop: "taxId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Select", name: "taxId", required: "" },
                  model: {
                    value: _vm.formData.taxId,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "taxId", $$v)
                    },
                    expression: "formData.taxId"
                  }
                },
                _vm._l(_vm.taxes, function(tax) {
                  return _c("el-option", {
                    key: tax.id,
                    attrs: { label: tax.name, value: tax.id }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        {
          staticClass: "half-width",
          attrs: { label: "Supplied By", prop: "suppliedBy" }
        },
        [
          _c("el-input", {
            attrs: {
              type: "text",
              name: "suppliedBy",
              placeholder: "Supplied By"
            },
            model: {
              value: _vm.formData.suppliedBy,
              callback: function($$v) {
                _vm.$set(_vm.formData, "suppliedBy", $$v)
              },
              expression: "formData.suppliedBy"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Short Description", prop: "shortDescription" } },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 2, maxRows: 5 },
              name: "shortDescription",
              placeholder: "Description",
              maxlength: "400",
              "show-word-limit": ""
            },
            model: {
              value: _vm.formData.shortDescription,
              callback: function($$v) {
                _vm.$set(_vm.formData, "shortDescription", $$v)
              },
              expression: "formData.shortDescription"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "half-width",
          attrs: { label: "Hsn Code", prop: "hsnCode" }
        },
        [
          _c("el-input", {
            attrs: { name: "hsnCode", placeholder: "Hsn Code" },
            model: {
              value: _vm.formData.hsnCode,
              callback: function($$v) {
                _vm.$set(_vm.formData, "hsnCode", $$v)
              },
              expression: "formData.hsnCode"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "half-width",
          attrs: { label: "Country Of Origin", prop: "countryOfOrigin" }
        },
        [
          _c("el-input", {
            attrs: { type: "text", name: "countryOfOrigin" },
            model: {
              value: _vm.formData.countryOfOrigin,
              callback: function($$v) {
                _vm.$set(_vm.formData, "countryOfOrigin", $$v)
              },
              expression: "formData.countryOfOrigin"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "half-width",
          attrs: { label: "Packing Size", prop: "packingSize" }
        },
        [
          _c("el-input", {
            attrs: { type: "text", name: "packingSize" },
            model: {
              value: _vm.formData.packingSize,
              callback: function($$v) {
                _vm.$set(_vm.formData, "packingSize", $$v)
              },
              expression: "formData.packingSize"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "half-width",
          attrs: { label: "Packing Type", prop: "packingType" }
        },
        [
          _c("el-input", {
            attrs: { type: "text", name: "packingType" },
            model: {
              value: _vm.formData.packingType,
              callback: function($$v) {
                _vm.$set(_vm.formData, "packingType", $$v)
              },
              expression: "formData.packingType"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "half-width", attrs: { label: "MOQ", prop: "moq" } },
        [
          _c("el-input", {
            attrs: { type: "text", name: "moq" },
            model: {
              value: _vm.formData.moq,
              callback: function($$v) {
                _vm.$set(_vm.formData, "moq", $$v)
              },
              expression: "formData.moq"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "half-width",
          attrs: { label: "Shelf Life", prop: "shelfLife" }
        },
        [
          _c("el-input", {
            attrs: { type: "text", name: "shelfLife" },
            model: {
              value: _vm.formData.shelfLife,
              callback: function($$v) {
                _vm.$set(_vm.formData, "shelfLife", $$v)
              },
              expression: "formData.shelfLife"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "half-width",
          attrs: { label: "Storage Condition", prop: "storageCondition" }
        },
        [
          _c("el-input", {
            attrs: { type: "text", name: "storageCondition" },
            model: {
              value: _vm.formData.storageCondition,
              callback: function($$v) {
                _vm.$set(_vm.formData, "storageCondition", $$v)
              },
              expression: "formData.storageCondition"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Dispatch Days" } },
        [
          _c("el-input-number", {
            attrs: { name: "dispatchDays", size: "mini", min: 0 },
            model: {
              value: _vm.formData.dispatchDays,
              callback: function($$v) {
                _vm.$set(_vm.formData, "dispatchDays", $$v)
              },
              expression: "formData.dispatchDays"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              attrs: { loading: _vm.loading, type: "success" },
              on: { click: _vm.submitForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              attrs: { type: "reset" },
              on: { click: _vm.resetForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }