var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        { attrs: { type: "border-card" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Info" } },
            [_c("orders-info-tab", { attrs: { data: _vm.orderInfoData } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "User" } },
            [_c("orders-user-tab", { attrs: { data: _vm.orderInfoData } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Sellers" } },
            [_c("orders-seller-tab", { attrs: { "order-id": _vm.orderId } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }