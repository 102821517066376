"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTransaction = exports.initEpayRefund = exports.getTransactions = exports.getTransactionById = exports.fileTransferHeaders = exports.deleteTransaction = exports.defaultTransactionData = exports.createTransaction = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultTransactionData = {
  id: 0,
  orderId: undefined,
  txnid: '',
  mihpayid: '',
  status: '',
  unmappedstatus: '',
  cardCategory: '',
  amount: undefined,
  net_amount_debit: undefined,
  payment_source: ''
};
exports.defaultTransactionData = defaultTransactionData;
var getTransactions = function getTransactions(params) {
  return (0, _request.default)({
    url: '/transactions',
    method: 'get',
    params: params
  });
};
exports.getTransactions = getTransactions;
var createTransaction = function createTransaction(data) {
  return (0, _request.default)({
    url: '/transactions',
    method: 'post',
    data: data
  });
};
exports.createTransaction = createTransaction;
var getTransactionById = function getTransactionById(id) {
  return (0, _request.default)({
    url: "/transactions/".concat(id),
    method: 'get'
  });
};
exports.getTransactionById = getTransactionById;
var updateTransaction = function updateTransaction(id, data) {
  return (0, _request.default)({
    url: "/transactions/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateTransaction = updateTransaction;
var deleteTransaction = function deleteTransaction(id) {
  return (0, _request.default)({
    url: "/transactions/".concat(id),
    method: 'delete'
  });
};
exports.deleteTransaction = deleteTransaction;
var initEpayRefund = function initEpayRefund(data) {
  return (0, _request.default)({
    url: '/transactions/init-epay-refund',
    method: 'post',
    data: data
  });
};
exports.initEpayRefund = initEpayRefund;