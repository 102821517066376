"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.includes");
require("core-js/modules/es.string.includes");
var _vue = _interopRequireDefault(require("vue"));
var _errorLog = require("@/store/modules/error-log");
var _validate = require("@/utils/validate");
var _settings = _interopRequireDefault(require("@/settings"));
var needErrorLog = _settings.default.errorLog;
var checkNeed = function checkNeed() {
  var env = process.env.NODE_ENV;
  if ((0, _validate.isArray)(needErrorLog) && env) {
    return needErrorLog.includes(env);
  }
  return false;
};
if (checkNeed()) {
  _vue.default.config.errorHandler = function (err, vm, info) {
    _errorLog.ErrorLogModule.AddErrorLog({
      err: err,
      vm: vm,
      info: info,
      url: window.location.href
    });
  };
}