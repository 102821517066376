"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'battery': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M32 12h-4v-2c0-1.1-.898-2-2-2H2c-1.102 0-2 .9-2 2v12c0 1.102.898 2 2 2h24c1.102 0 2-.898 2-2v-2h4v-8zm-8 8h-8v-8h8v8z" _fill="#030104"/>'
  }
});