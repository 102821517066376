"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
_vue.default.use(_vuex.default);
// Declare empty store first, dynamically register all modules later.
var _default = new _vuex.default.Store({});
exports.default = _default;