"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrdersSeller = exports.getSellerOrderInvoiceById = exports.getOrdersSellers = exports.getOrdersSellerByOrderId = exports.getOrdersSellerById = exports.fileTransferHeaders = exports.exportOrdersSellers = exports.defaultOrdersSellerData = exports.countOrdersSellers = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultOrdersSellerData = {
  id: 0,
  enabled: true,
  orderId: 0,
  sellerId: 0,
  status: "",
  comments: "",
  total: 0,
  shippingCharges: 0,
  taxedAmount: 0,
  discountAmount: 0,
  grandTotal: 0,
  totalProducts: 0,
  totalQuantity: 0,
  orderTimestamp: "",
  freightCharges: 0,
  rating: 0,
  orderDispatchedTimestamp: null,
  deliveredTimestamp: null,
  deliveryConfirmedTimestamp: null,
  notDeliveredTimestamp: null,
  cancelledTimestamp: null,
  lrNumber: null,
  lrReceipt: null,
  invoiceAttachment: null,
  qualityCoa: null,
  nominatedTransportDetails: null,
  notDeliveredReason: null,
  returnAmount: 0,
  returnQuantity: 0,
  returnDate: null,
  isPaid: false,
  sellerPaymentDate: null,
  userId: 0,
  user: {
    id: null
  },
  order: {
    id: null
  },
  seller: {
    id: null
  }
};
exports.defaultOrdersSellerData = defaultOrdersSellerData;
var getOrdersSellers = function getOrdersSellers(params) {
  return (0, _request.default)({
    url: "/orders-sellers",
    method: "get",
    params: params
  });
};
exports.getOrdersSellers = getOrdersSellers;
var getOrdersSellerById = function getOrdersSellerById(id) {
  return (0, _request.default)({
    url: "/orders-sellers/".concat(id),
    method: "get"
  });
};
exports.getOrdersSellerById = getOrdersSellerById;
var getOrdersSellerByOrderId = function getOrdersSellerByOrderId(orderId) {
  return (0, _request.default)({
    url: "/orders-sellers/order/".concat(orderId),
    method: "get"
  });
};
exports.getOrdersSellerByOrderId = getOrdersSellerByOrderId;
var updateOrdersSeller = function updateOrdersSeller(id, data) {
  return (0, _request.default)({
    url: "/orders-sellers/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateOrdersSeller = updateOrdersSeller;
var countOrdersSellers = function countOrdersSellers(params) {
  return (0, _request.default)({
    url: "/orders-sellers/count",
    method: "get",
    params: params
  });
};
exports.countOrdersSellers = countOrdersSellers;
var exportOrdersSellers = function exportOrdersSellers(data) {
  return (0, _request.default)({
    url: "/orders-sellers/export",
    method: "post",
    data: data
  });
};
exports.exportOrdersSellers = exportOrdersSellers;
var getSellerOrderInvoiceById = function getSellerOrderInvoiceById(id) {
  return (0, _request.default)({
    url: "/orders-sellers/pdf/".concat(id, "/1"),
    method: "get"
  });
};
exports.getSellerOrderInvoiceById = getSellerOrderInvoiceById;