var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Name", prop: "name" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Select Document" },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  },
                  _vm._l(_vm.documentList, function(item) {
                    return _c("el-option", {
                      key: item.key,
                      attrs: { label: item.title, value: item.key }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Status", prop: "status" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Select Status" },
                    model: {
                      value: _vm.formData.status,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status"
                    }
                  },
                  _vm._l(["Pending", "Approved", "Rejected"], function(item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Document No", prop: "documentNo" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "documentNo",
                    required: "",
                    placeholder: "Document No"
                  },
                  model: {
                    value: _vm.formData.documentNo,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "documentNo", $$v)
                    },
                    expression: "formData.documentNo"
                  }
                })
              ],
              1
            ),
            _vm.formData.status === "Rejected"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Comments", prop: "comments" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 2, maxRows: 5 },
                        name: "comments",
                        placeholder: "enter your comments",
                        maxlength: "200",
                        "show-word-limit": ""
                      },
                      model: {
                        value: _vm.formData.comments,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "comments", $$v)
                        },
                        expression: "formData.comments"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isEdit
              ? _c(
                  "el-form-item",
                  { attrs: { label: "File", prop: "file" } },
                  [
                    _c("file-upload", {
                      attrs: { url: "/users-documents/upload" },
                      on: { change: _vm.userDocumentUploaded }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }