"use strict";

require("./404");
require("./agent");
require("./almond");
require("./auction");
require("./back-top");
require("./banner");
require("./battery");
require("./bug");
require("./building");
require("./chart");
require("./clipboard");
require("./component");
require("./dashboard");
require("./documentation");
require("./drag");
require("./edit");
require("./education");
require("./email");
require("./example");
require("./excel");
require("./exit-fullscreen");
require("./eye-off");
require("./eye-on");
require("./form");
require("./fullscreen");
require("./globe");
require("./guide-2");
require("./guide");
require("./hamburger");
require("./icon");
require("./inr");
require("./international");
require("./language");
require("./like");
require("./link");
require("./list");
require("./location");
require("./lock");
require("./message");
require("./money");
require("./nested");
require("./password");
require("./pdf");
require("./people");
require("./peoples");
require("./qq");
require("./riyal");
require("./sack");
require("./scale");
require("./search");
require("./shopping");
require("./size");
require("./skill");
require("./star");
require("./support");
require("./tab");
require("./table");
require("./tax");
require("./theme");
require("./tree-table");
require("./tree");
require("./user");
require("./wechat");
require("./zip");