"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateNotifications = exports.getNotificationsById = exports.getNotifications = exports.getFilters = exports.deleteNotifications = exports.defaultNotificationsData = exports.createNotifications = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultNotificationsData = {
  id: 0,
  enabled: true,
  title: '',
  message: '',
  image: null,
  link: null,
  page: null,
  params: null,
  sendToAll: false,
  appVersion: null,
  platform: null,
  stateId: null,
  state: {
    id: null
  },
  countryId: null,
  country: {
    id: null
  },
  cityId: null,
  city: {
    id: null
  }
};
exports.defaultNotificationsData = defaultNotificationsData;
var getNotifications = function getNotifications(params) {
  return (0, _request.default)({
    url: '/notifications',
    method: 'get',
    params: params
  });
};
exports.getNotifications = getNotifications;
var getNotificationsById = function getNotificationsById(id) {
  return (0, _request.default)({
    url: "/notifications/".concat(id),
    method: 'get'
  });
};
exports.getNotificationsById = getNotificationsById;
var updateNotifications = function updateNotifications(id, data) {
  return (0, _request.default)({
    url: "/notifications/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateNotifications = updateNotifications;
var deleteNotifications = function deleteNotifications(id) {
  return (0, _request.default)({
    url: "/notifications/".concat(id),
    method: 'delete'
  });
};
exports.deleteNotifications = deleteNotifications;
var createNotifications = function createNotifications(data) {
  return (0, _request.default)({
    url: '/notifications/',
    method: 'post',
    data: data
  });
};
exports.createNotifications = createNotifications;
var getFilters = function getFilters(data) {
  return (0, _request.default)({
    url: '/notifications/fetch',
    method: 'post',
    data: data
  });
};
exports.getFilters = getFilters;