"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.array.filter");
require("core-js/modules/es.object.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _admin = require("@/store/modules/admin");
var _qs = _interopRequireDefault(require("qs"));
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000
  // withCredentials: true // send cookies when cross-domain requests
});
// Request interceptors
service.interceptors.request.use(function (config) {
  // Add X-Access-Token header to every request, you can add other custom headers here
  if (_admin.AdminModule.token) {
    config.headers.Authorization = 'Bearer ' + _admin.AdminModule.token;
  }
  // if type get then serialize params
  if (config.method === 'get') {
    // check if filter exist
    if (config.params && config.params.filter && config.params.filter.length > 0) {
      config.paramsSerializer = function (params) {
        return _qs.default.stringify(params, {
          arrayFormat: 'repeat'
        });
      };
    }
  } else if (config.method === 'patch') {
    config.data.updatedBy = _admin.AdminModule.id;
  } else if (config.method === 'post' && config.data) {
    config.data.createdBy = _admin.AdminModule.id;
  }
  return config;
}, function (error) {
  Promise.reject(error);
});
// Response interceptors
service.interceptors.response.use(function (response) {
  // Some example codes here:
  // code == 20000: success
  // code == 50001: invalid access token
  // code == 50002: already login in other place
  // code == 50003: access token expired
  // code == 50004: invalid user (user not exist)
  // code == 50005: username or password is incorrect
  // You can change this part for your own usage.
  return response.data;
  /*
  if (res.code !== 20000) {
    Message({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    })
    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      MessageBox.confirm(
        'You have been logged out, you can cancel to stay on this page, or log in again',
        'Confirm logout',
        {
          confirmButtonText: 're-register',
          cancelButtonText: 'cancel',
          type: 'warning'
        }
      ).then(() => {
        AdminModule.ResetToken()
        location.reload() // To prevent bugs from vue-router
      })
    }
    return Promise.reject(new Error(res.message || 'Error'))
  } else {
    return response.data
  }
  */
}, function (error) {
  if (!error.response) {
    // network error
    var errorMessage = 'Network error!';
    (0, _elementUi.Message)({
      message: errorMessage,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(errorMessage);
  } else if (error.response) {
    // force logout if status code is 403
    if (error.response.status === 403) {
      _admin.AdminModule.ResetToken();
      location.reload();
    } else if (error.response.data.message) {
      (0, _elementUi.Message)({
        message: error.response.data.message,
        type: 'error',
        duration: 5 * 1000
      });
    }
  }
  return Promise.reject(error.response.data);
});
var _default = service;
exports.default = _default;