var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "formPass",
      attrs: { model: _vm.formPass, rules: _vm.rules, autocomplete: "off" }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "New Password", prop: "newPassword" } },
        [
          _c("el-input", {
            attrs: { type: "password" },
            model: {
              value: _vm.formPass.newPassword,
              callback: function($$v) {
                _vm.$set(
                  _vm.formPass,
                  "newPassword",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formPass.newPassword"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Confirm Password", prop: "password" } },
        [
          _c("el-input", {
            attrs: { type: "password" },
            model: {
              value: _vm.formPass.password,
              callback: function($$v) {
                _vm.$set(
                  _vm.formPass,
                  "password",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formPass.password"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.passLoading,
                  expression: "passLoading"
                }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.submitForm }
            },
            [_vm._v(" Update ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }