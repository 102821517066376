"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBroker = exports.getBrokers = exports.getBrokerById = exports.fileTransferHeaders = exports.deleteBroker = exports.defaultBrokerData = exports.createBroker = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultBrokerData = {
  id: 0,
  enabled: true,
  name: '',
  mobileNumber: ''
};
exports.defaultBrokerData = defaultBrokerData;
var getBrokers = function getBrokers(params) {
  return (0, _request.default)({
    url: '/brokers',
    method: 'get',
    params: params
  });
};
exports.getBrokers = getBrokers;
var getBrokerById = function getBrokerById(id) {
  return (0, _request.default)({
    url: "/brokers/".concat(id),
    method: 'get'
  });
};
exports.getBrokerById = getBrokerById;
var updateBroker = function updateBroker(id, data) {
  return (0, _request.default)({
    url: "/brokers/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateBroker = updateBroker;
var deleteBroker = function deleteBroker(id) {
  return (0, _request.default)({
    url: "/brokers/".concat(id),
    method: 'delete'
  });
};
exports.deleteBroker = deleteBroker;
var createBroker = function createBroker(data) {
  return (0, _request.default)({
    url: '/brokers/',
    method: 'post',
    data: data
  });
};
exports.createBroker = createBroker;