"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateContact = exports.getContacts = exports.getContactById = exports.fileTransferHeaders = exports.deleteContact = exports.defaultContactData = exports.createContact = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultContactData = {
  id: 0,
  enabled: true,
  userId: 0,
  user: {
    id: null
  },
  organizationId: 0,
  organization: {
    id: null
  },
  emailAddress: '',
  mobileNumber: '',
  contactPersonName: ''
};
exports.defaultContactData = defaultContactData;
var getContacts = function getContacts(params) {
  return (0, _request.default)({
    url: '/contacts',
    method: 'get',
    params: params
  });
};
exports.getContacts = getContacts;
var getContactById = function getContactById(id) {
  return (0, _request.default)({
    url: "/contacts/".concat(id),
    method: 'get'
  });
};
exports.getContactById = getContactById;
var updateContact = function updateContact(id, data) {
  return (0, _request.default)({
    url: "/contacts/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateContact = updateContact;
var deleteContact = function deleteContact(id) {
  return (0, _request.default)({
    url: "/contacts/".concat(id),
    method: 'delete'
  });
};
exports.deleteContact = deleteContact;
var createContact = function createContact(data) {
  return (0, _request.default)({
    url: '/contacts/',
    method: 'post',
    data: data
  });
};
exports.createContact = createContact;