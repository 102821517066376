"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userCount = exports.updateUsersDocument = exports.updateUser = exports.register = exports.logout = exports.login = exports.getUsersWallets = exports.getUsersDocuments = exports.getUsersDocumentById = exports.getUsers = exports.getUserRoles = exports.getUserCards = exports.getUserById = exports.getUserByEmail = exports.getDocumentNames = exports.forceLogout = exports.deleteUser = exports.defaultUsersDocumentData = exports.defaultUserWalletData = exports.defaultUserData = exports.defaultSellerData = exports.createUsersDocument = exports.addFundsToUserWaller = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultUserData = {
  id: 0,
  enabled: true,
  fullName: "",
  emailAddress: "",
  mobileNumber: "",
  avatar: "",
  vrlOnly: undefined,
  organizationId: undefined,
  broker: {
    id: null
  },
  organization: {
    id: null,
    name: ""
  },
  usersRole: {
    id: null,
    name: ""
  },
  brokerName: "",
  // fssaiNumber: '',
  // gstNumber: '',
  // panNumber: '',
  usersRoleId: undefined,
  brokerId: undefined,
  // countryCode: '',
  // preferredLanguage: '',
  // isEmailVerified: undefined,
  // isMobileVerified: undefined,
  // gender: '',
  password: "",
  // referralCode: '',
  // canUseReferralCode: undefined,
  // lastLocation: '',
  // pushId: '',
  // platform: '',
  // version: '',
  // uuid: '',
  // model: '',
  // deviceId: '',
  // device: '',
  // OSVersion: '',
  // uniqueID: '',
  // manufacturer: '',
  // appVersion: '',
  referalCode: ""
};
exports.defaultUserData = defaultUserData;
var defaultUserWalletData = {
  id: 0,
  enabled: false,
  amount: 0,
  action: "credit",
  userId: null,
  orderId: null,
  user: {
    id: null
  },
  order: {
    id: null
  }
};
exports.defaultUserWalletData = defaultUserWalletData;
var defaultSellerData = {
  id: 0,
  sellerDepositAmount: 0,
  sellerTradeLimit: 0,
  sellerAvailableAmount: 0,
  sellerUsedAmount: 0,
  sellerOutstandingAmount: 0,
  commission: 0,
  isSeller: false,
  isSellerRegistered: false,
  interestedProducts: null,
  monthlyCapacity: 0,
  location: null,
  sellersDocument: []
};
exports.defaultSellerData = defaultSellerData;
var defaultUsersDocumentData = {
  id: 0,
  enabled: true,
  name: "",
  file: "",
  status: "Pending",
  comments: null,
  documentNo: null,
  user: {
    id: null
  }
};
exports.defaultUsersDocumentData = defaultUsersDocumentData;
var getUsers = function getUsers(params) {
  return (0, _request.default)({
    url: "/users",
    method: "get",
    params: params
  });
};
exports.getUsers = getUsers;
var getUserById = function getUserById(id) {
  return (0, _request.default)({
    url: "/users/".concat(id),
    method: "get"
  });
};
exports.getUserById = getUserById;
var getUserByEmail = function getUserByEmail(adminemail) {
  return (0, _request.default)({
    url: "/users/".concat(adminemail),
    method: "get"
  });
};
exports.getUserByEmail = getUserByEmail;
var updateUser = function updateUser(id, data) {
  return (0, _request.default)({
    url: "/users/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateUser = updateUser;
var deleteUser = function deleteUser(adminname) {
  return (0, _request.default)({
    url: "/users/".concat(adminname),
    method: "delete"
  });
};
exports.deleteUser = deleteUser;
var login = function login(data) {
  return (0, _request.default)({
    url: "/users/login",
    method: "post",
    data: data
  });
};
exports.login = login;
var logout = function logout() {
  return (0, _request.default)({
    url: "/users/logout",
    method: "post"
  });
};
exports.logout = logout;
var forceLogout = function forceLogout(data) {
  return (0, _request.default)({
    url: "/users/force-logout",
    method: "post",
    data: data
  });
};
exports.forceLogout = forceLogout;
var register = function register(data) {
  return (0, _request.default)({
    url: "/users",
    method: "post",
    data: data
  });
};
exports.register = register;
var userCount = function userCount(params) {
  return (0, _request.default)({
    url: "/users/count",
    method: "get",
    params: params
  });
};
exports.userCount = userCount;
var getUserCards = function getUserCards() {
  return (0, _request.default)({
    url: "/users-cards",
    method: "get"
  });
};
exports.getUserCards = getUserCards;
var getUserRoles = function getUserRoles(params) {
  return (0, _request.default)({
    url: "/users-roles",
    method: "get",
    params: params
  });
};
exports.getUserRoles = getUserRoles;
var getUsersWallets = function getUsersWallets(params) {
  return (0, _request.default)({
    url: "/wallet-transactions",
    method: "get",
    params: params
  });
};
exports.getUsersWallets = getUsersWallets;
var addFundsToUserWaller = function addFundsToUserWaller(data) {
  return (0, _request.default)({
    url: "/wallet-transactions",
    method: "post",
    data: data
  });
};
// user documents
exports.addFundsToUserWaller = addFundsToUserWaller;
var getUsersDocuments = function getUsersDocuments(params) {
  return (0, _request.default)({
    url: "/users-documents",
    method: "get",
    params: params
  });
};
exports.getUsersDocuments = getUsersDocuments;
var getUsersDocumentById = function getUsersDocumentById(id) {
  return (0, _request.default)({
    url: "/users-documents/".concat(id),
    method: "get"
  });
};
exports.getUsersDocumentById = getUsersDocumentById;
var getDocumentNames = function getDocumentNames() {
  return (0, _request.default)({
    url: "/users-documents/list-names",
    method: "get"
  });
};
exports.getDocumentNames = getDocumentNames;
var updateUsersDocument = function updateUsersDocument(id, data) {
  return (0, _request.default)({
    url: "/users-documents/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateUsersDocument = updateUsersDocument;
var createUsersDocument = function createUsersDocument(data) {
  return (0, _request.default)({
    url: "/users-documents/",
    method: "post",
    data: data
  });
};
exports.createUsersDocument = createUsersDocument;