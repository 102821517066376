"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCountry = exports.getCountryById = exports.getCountries = exports.deleteCountry = exports.defaultCountryData = exports.createCountry = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultCountryData = {
  id: 0,
  enabled: true,
  code: '',
  name: '',
  zipCodeMinLength: 0,
  zipCodeMaxLength: 0
};
exports.defaultCountryData = defaultCountryData;
var getCountries = function getCountries(params) {
  return (0, _request.default)({
    url: '/countries',
    method: 'get',
    params: params
  });
};
exports.getCountries = getCountries;
var getCountryById = function getCountryById(id) {
  return (0, _request.default)({
    url: "/countries/".concat(id),
    method: 'get'
  });
};
exports.getCountryById = getCountryById;
var updateCountry = function updateCountry(id, data) {
  return (0, _request.default)({
    url: "/countries/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateCountry = updateCountry;
var deleteCountry = function deleteCountry(id) {
  return (0, _request.default)({
    url: "/countries/".concat(id),
    method: 'delete'
  });
};
exports.deleteCountry = deleteCountry;
var createCountry = function createCountry(data) {
  return (0, _request.default)({
    url: '/countries/',
    method: 'post',
    data: data
  });
};
exports.createCountry = createCountry;