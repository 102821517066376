"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCity = exports.getCityById = exports.getCities = exports.deleteCity = exports.defaultCityData = exports.createCity = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultCityData = {
  id: 0,
  enabled: true,
  allowCod: false,
  subscriptionRequired: false,
  stateId: undefined,
  countryId: undefined,
  name: '',
  country: {
    id: undefined
  },
  state: {
    id: undefined
  }
};
exports.defaultCityData = defaultCityData;
var getCities = function getCities(params) {
  return (0, _request.default)({
    url: '/cities',
    method: 'get',
    params: params
  });
};
exports.getCities = getCities;
var getCityById = function getCityById(id) {
  return (0, _request.default)({
    url: "/cities/".concat(id),
    method: 'get'
  });
};
exports.getCityById = getCityById;
var updateCity = function updateCity(id, data) {
  return (0, _request.default)({
    url: "/cities/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateCity = updateCity;
var deleteCity = function deleteCity(id) {
  return (0, _request.default)({
    url: "/cities/".concat(id),
    method: 'delete'
  });
};
exports.deleteCity = deleteCity;
var createCity = function createCity(data) {
  return (0, _request.default)({
    url: '/cities/',
    method: 'post',
    data: data
  });
};
exports.createCity = createCity;