"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateRole = exports.updateAdminRole = exports.getRoutes = exports.getRoles = exports.getRoleById = exports.getAdminRole = exports.getAdminPages = exports.deleteRole = exports.deleteAdminRole = exports.defaultAdminRoleData = exports.createRole = exports.createAdminRole = exports.adminRoles = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultAdminRoleData = {
  id: 0,
  enabled: true,
  name: "",
  adminPages: []
};
exports.defaultAdminRoleData = defaultAdminRoleData;
var getRoles = function getRoles(params) {
  return (0, _request.default)({
    url: "/admins-roles",
    method: "get",
    params: params
  });
};
exports.getRoles = getRoles;
var adminRoles = function adminRoles(data) {
  return (0, _request.default)({
    url: "/admins-roles/getRoles",
    method: "post",
    data: data
  });
};
exports.adminRoles = adminRoles;
var getAdminRole = function getAdminRole(id) {
  return (0, _request.default)({
    url: "/admins-roles/getRolesById",
    method: "post",
    data: {
      id: id
    }
  });
};
exports.getAdminRole = getAdminRole;
var updateAdminRole = function updateAdminRole(data) {
  return (0, _request.default)({
    url: "/admins-roles/update",
    method: "post",
    data: data
  });
};
exports.updateAdminRole = updateAdminRole;
var createAdminRole = function createAdminRole(data) {
  return (0, _request.default)({
    url: "/admins-roles/add",
    method: "post",
    data: data
  });
};
exports.createAdminRole = createAdminRole;
var deleteAdminRole = function deleteAdminRole(id) {
  return (0, _request.default)({
    url: "/admins-roles/delete",
    method: "post",
    data: {
      id: id
    }
  });
};
exports.deleteAdminRole = deleteAdminRole;
var getAdminPages = function getAdminPages() {
  return (0, _request.default)({
    url: "admin-pages",
    method: "get",
    params: {
      filter: "enabled||eq||true"
    }
  });
};
exports.getAdminPages = getAdminPages;
var getRoleById = function getRoleById(id) {
  return (0, _request.default)({
    url: "/admins-roles/".concat(id),
    method: "get"
  });
};
exports.getRoleById = getRoleById;
var updateRole = function updateRole(id, data) {
  return (0, _request.default)({
    url: "/admins-roles/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateRole = updateRole;
var deleteRole = function deleteRole(id) {
  return (0, _request.default)({
    url: "/admins-roles/".concat(id),
    method: "delete"
  });
};
exports.deleteRole = deleteRole;
var createRole = function createRole(data) {
  return (0, _request.default)({
    url: "/admins-roles/",
    method: "post",
    data: data
  });
};
exports.createRole = createRole;
var getRoutes = function getRoutes(params) {
  return (0, _request.default)({
    url: "/routes",
    method: "get",
    params: params
  });
};
exports.getRoutes = getRoutes;