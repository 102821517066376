"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.object.get-own-property-descriptor");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.string.iterator");
require("core-js/modules/es.weak-map");
require("core-js/modules/web.dom-collections.iterator");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locationSubMenu = void 0;
var _typeof2 = _interopRequireDefault(require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/typeof"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var locationSubMenu = [{
  path: "/countries/list",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/countries/list.vue"));
    });
  },
  name: "Countries",
  meta: {
    title: "locations.countries.title",
    noCache: true,
    icon: "globe"
  }
}, {
  path: "/countries/edit/:id(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/countries/manage.vue"));
    });
  },
  name: "Edit Country",
  props: {
    isEdit: true
  },
  meta: {
    title: "locations.countries.edit",
    noCache: true,
    activeMenu: "/countries/list",
    hidden: true
  }
}, {
  path: "/countries/add",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/countries/manage.vue"));
    });
  },
  name: "Add Country",
  props: {
    isEdit: false
  },
  meta: {
    title: "locations.countries.add",
    noCache: true,
    activeMenu: "/countries/list",
    hidden: true
  }
}, {
  path: "/states/list/:countryId(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/states/list.vue"));
    });
  },
  name: "States",
  meta: {
    title: "locations.states.title",
    noCache: true,
    hidden: true
  }
}, {
  path: "/states/edit/:id(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/states/manage.vue"));
    });
  },
  name: "Edit State",
  props: {
    isEdit: true
  },
  meta: {
    title: "locations.states.edit",
    noCache: true,
    activeMenu: "/states/list",
    hidden: true
  }
}, {
  path: "/states/add/:countryId(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/states/manage.vue"));
    });
  },
  name: "Add State",
  props: {
    isEdit: false
  },
  meta: {
    title: "locations.states.add",
    noCache: true,
    activeMenu: "/states/list",
    hidden: true
  }
}, {
  path: "/cities/list/:stateId(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/cities/list.vue"));
    });
  },
  name: "Cities",
  meta: {
    title: "locations.cities.title",
    noCache: true,
    hidden: true
  }
}, {
  path: "/cities/edit/:id(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/cities/manage.vue"));
    });
  },
  name: "Edit City",
  props: {
    isEdit: true
  },
  meta: {
    title: "locations.cities.edit",
    noCache: true,
    activeMenu: "/cities/list",
    hidden: true
  }
}, {
  path: "/cities/add/:stateId(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/cities/manage.vue"));
    });
  },
  name: "Add City",
  props: {
    isEdit: false
  },
  meta: {
    title: "locations.cities.add",
    noCache: true,
    activeMenu: "/cities/list",
    hidden: true
  }
},
// VRL
{
  path: "/vrls/list/:cityId(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/cities/vrls/list.vue"));
    });
  },
  name: "VRL Delivery Locations",
  meta: {
    title: "locations.cities.vrls.title",
    noCache: true,
    hidden: true
  }
}, {
  path: "/vrls/edit/:id(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/cities/vrls/manage.vue"));
    });
  },
  name: "Edit VRL",
  props: {
    isEdit: true
  },
  meta: {
    title: "locations.cities.vrls.edit",
    noCache: true,
    activeMenu: "/vrls/list",
    hidden: true
  }
}, {
  path: "/vrls/add/:cityId(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/locations/cities/vrls/manage.vue"));
    });
  },
  name: "Add VRL",
  props: {
    isEdit: false
  },
  meta: {
    title: "locations.cities.vrls.add",
    noCache: true,
    activeMenu: "/vrls/list",
    hidden: true
  }
}];
exports.locationSubMenu = locationSubMenu;