var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-collapse",
        {
          attrs: { accordion: "" },
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        _vm._l(_vm.list, function(data) {
          return _c(
            "el-collapse-item",
            { key: data.id, attrs: { name: data.id } },
            [
              _c("template", { slot: "title" }, [
                _vm._v(
                  " #" +
                    _vm._s(data.id) +
                    " " +
                    _vm._s(data.seller.fullName) +
                    " "
                )
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "text item" }, [
                          _c("b", [_vm._v(" Placed On:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  data.createdTimestamp,
                                  "Do MMM YYYY, h:mm:ss a"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("div", { staticClass: "text item" }, [
                          _c("b", [_vm._v("Total Product:")]),
                          _vm._v(" " + _vm._s(data.totalProducts) + " ")
                        ]),
                        _c("div", { staticClass: "text item" }, [
                          _c("b", [_vm._v("Total Quantity :")]),
                          _vm._v(" " + _vm._s(data.totalQuantity) + " ")
                        ])
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticClass: "text item" },
                          [
                            _c("b", [_vm._v(" Tax Amount:")]),
                            _c("inr", { attrs: { number: data.taxedAmount } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "text item" },
                          [
                            _c("b", [_vm._v("Total :")]),
                            _c("inr", { attrs: { number: data.total } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "text item" },
                          [
                            _c("b", [_vm._v("Grand Total :")]),
                            _c("inr", { attrs: { number: data.grandTotal } })
                          ],
                          1
                        )
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "text item" }, [
                          _c("b", [_vm._v("Status:")]),
                          _vm._v(" " + _vm._s(data.status) + " ")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              data.ordersProduct && data.ordersProduct.length
                ? _c(
                    "div",
                    [
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [_vm._v(" Product Details ")]
                      ),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: data.ordersProduct }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "product.name",
                              label: "Product",
                              width: "260px"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "quantity",
                              label: "Qty",
                              width: "100px"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unitPrice",
                              label: "Price",
                              width: "160px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            parseFloat(scope.row.salePrice) ||
                                              parseFloat(scope.row.unitPrice)
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "tax",
                              label: "Tax",
                              width: "140px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.taxedAmount) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " (" +
                                          _vm._s(scope.row.taxRate) +
                                          " %) "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "discountAmount",
                              label: "Discount",
                              width: "120px"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "freightCharges",
                              label: "Freight",
                              width: "120px"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "subTotal",
                              label: "Total",
                              width: "180px"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              [
                "Order Dispatched",
                "Order Delivered",
                "Delivery Confirmed",
                "Not Delivered"
              ].includes(data.status)
                ? _c(
                    "div",
                    [
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [_vm._v(" Despatch Details ")]
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "text item" }, [
                              _c("b", [_vm._v(" LR Number:")]),
                              _vm._v(" " + _vm._s(data.lrNumber || " - ") + " ")
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", { staticClass: "text item" }, [
                              _c("b", [_vm._v(" Invoice Number:")]),
                              _vm._v(
                                " " + _vm._s(data.invoiceNumber || " - ") + " "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c(
                              "div",
                              { staticClass: "text item" },
                              [
                                _c("b", [_vm._v("LR Receipt: ")]),
                                data.lrReceipt
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          href: data.lrReceipt,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-link"
                                        }),
                                        _vm._v(" View ")
                                      ]
                                    )
                                  : _c("span", [_vm._v(" - ")])
                              ],
                              1
                            )
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c(
                              "div",
                              { staticClass: "text item" },
                              [
                                _c("b", [_vm._v("Invoice Attachment: ")]),
                                data.invoiceAttachment
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          href: data.invoiceAttachment,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-link"
                                        }),
                                        _vm._v(" View ")
                                      ]
                                    )
                                  : _c("span", [_vm._v(" - ")])
                              ],
                              1
                            )
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c(
                              "div",
                              { staticClass: "text item" },
                              [
                                _c("b", [_vm._v("Quality COA: ")]),
                                data.qualityCoa
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          href: data.qualityCoa,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-link"
                                        }),
                                        _vm._v(" View ")
                                      ]
                                    )
                                  : _c("span", [_vm._v(" - ")])
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }