"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBanner = exports.getBanners = exports.getBannerById = exports.fileTransferHeaders = exports.deleteBanner = exports.defaultBannerData = exports.createBanner = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultBannerData = {
  id: 0,
  enabled: true,
  name: '',
  desktopImage: '',
  mobileImage: '',
  description: '',
  priority: 0,
  validFrom: undefined,
  validTill: undefined,
  link: undefined,
  page: undefined,
  params: null,
  sellerId: null,
  seller: {
    id: undefined
  },
  categoryId: null,
  category: {
    id: undefined
  },
  productId: null,
  product: {
    id: undefined
  }
};
exports.defaultBannerData = defaultBannerData;
var getBanners = function getBanners(params) {
  return (0, _request.default)({
    url: '/banners',
    method: 'get',
    params: params
  });
};
exports.getBanners = getBanners;
var getBannerById = function getBannerById(id) {
  return (0, _request.default)({
    url: "/banners/".concat(id),
    method: 'get'
  });
};
exports.getBannerById = getBannerById;
var updateBanner = function updateBanner(id, data) {
  return (0, _request.default)({
    url: "/banners/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateBanner = updateBanner;
var deleteBanner = function deleteBanner(id) {
  return (0, _request.default)({
    url: "/banners/".concat(id),
    method: 'delete'
  });
};
exports.deleteBanner = deleteBanner;
var createBanner = function createBanner(data) {
  return (0, _request.default)({
    url: '/banners/',
    method: 'post',
    data: data
  });
};
exports.createBanner = createBanner;