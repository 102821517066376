"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCoupon = exports.getCouponById = exports.getCoupon = exports.deleteCoupon = exports.defaultCouponData = exports.createCoupon = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _moment = _interopRequireDefault(require("moment"));
var defaultCouponData = {
  id: 0,
  enabled: true,
  name: '',
  description: '',
  visibility: true,
  code: '',
  firstTimeUser: true,
  oneTimeUse: true,
  validFrom: (0, _moment.default)().format('YYYY-MM-DD'),
  validTill: (0, _moment.default)().add(5, 'days').format('YYYY-MM-DD'),
  minimumAmount: 0,
  totalAvailable: 0,
  applyDiscount: 'percent',
  percentAmount: 0,
  discountAmount: 0,
  maximumDiscountAmount: 0
};
exports.defaultCouponData = defaultCouponData;
var getCoupon = function getCoupon(params) {
  return (0, _request.default)({
    url: '/coupons',
    method: 'get',
    params: params
  });
};
exports.getCoupon = getCoupon;
var getCouponById = function getCouponById(id) {
  return (0, _request.default)({
    url: "/coupons/".concat(id),
    method: 'get'
  });
};
exports.getCouponById = getCouponById;
var updateCoupon = function updateCoupon(id, data) {
  return (0, _request.default)({
    url: "/coupons/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateCoupon = updateCoupon;
var deleteCoupon = function deleteCoupon(id) {
  return (0, _request.default)({
    url: "/coupons/".concat(id),
    method: 'delete'
  });
};
exports.deleteCoupon = deleteCoupon;
var createCoupon = function createCoupon(data) {
  return (0, _request.default)({
    url: '/coupons/',
    method: 'post',
    data: data
  });
};
exports.createCoupon = createCoupon;