var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              "status-icon": "",
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Name", prop: "name" } },
              [
                _c("el-input", {
                  attrs: { name: "name", required: "", placeholder: "Name" },
                  model: {
                    value: _vm.formData.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Email Address", prop: "emailAddress" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "emailAddress",
                    required: "",
                    placeholder: "Email Address"
                  },
                  model: {
                    value: _vm.formData.emailAddress,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "emailAddress", $$v)
                    },
                    expression: "formData.emailAddress"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Mobile Number", prop: "mobileNumber" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "mobileNumber",
                    required: "",
                    placeholder: "Mobile Number"
                  },
                  model: {
                    value: _vm.formData.mobileNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "mobileNumber", $$v)
                    },
                    expression: "formData.mobileNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Address", prop: "address" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    name: "address",
                    required: "",
                    placeholder: "Address"
                  },
                  model: {
                    value: _vm.formData.address,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "address", $$v)
                    },
                    expression: "formData.address"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Priority", prop: "priority" } },
              [
                _c("el-input-number", {
                  attrs: {
                    name: "priority",
                    required: "",
                    placeholder: "Priority"
                  },
                  model: {
                    value: _vm.formData.priority,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "priority", $$v)
                    },
                    expression: "formData.priority"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }