"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es.object.get-own-property-descriptor");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.string.iterator");
require("core-js/modules/es.weak-map");
require("core-js/modules/web.dom-collections.iterator");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/typeof"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var subscriptionsRouter = {
  path: "/subscriptions",
  component: _index.default,
  redirect: "noredirect",
  name: "subscriptionManagement.title",
  meta: {
    title: "subscriptionManagement.title",
    icon: "tab"
  },
  children: [{
    path: "/plans/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/plans/list.vue"));
      });
    },
    name: "Plans",
    meta: {
      title: "subscriptionManagement.plans.title",
      noCache: true,
      icon: "star"
    }
  }, {
    path: "/plans/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/plans/manage.vue"));
      });
    },
    name: "subscriptionManagement.plans.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "subscriptionManagement.plans.manage",
      noCache: true,
      activeMenu: "/plans/list",
      hidden: true
    }
  }, {
    path: "/plans/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/plans/manage.vue"));
      });
    },
    name: "subscriptionManagement.plans.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "subscriptionManagement.plans.edit",
      noCache: true,
      activeMenu: "/plans/list",
      hidden: true
    }
  }, {
    path: "/subscriptions/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/subscriptions/list.vue"));
      });
    },
    name: "Subscriptions",
    meta: {
      title: "subscriptionManagement.subscriptions.title",
      noCache: true,
      icon: "list"
    }
  }, {
    path: "/subscriptions/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/subscriptions/manage.vue"));
      });
    },
    name: "subscriptionManagement.subscriptions.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "subscriptionManagement.subscriptions.manage",
      noCache: true,
      activeMenu: "/subscriptions/list",
      hidden: true
    }
  }, {
    path: "/subscriptions/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/subscriptions/manage.vue"));
      });
    },
    name: "subscriptionManagement.subscriptions.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "subscriptionManagement.subscriptions.edit",
      noCache: true,
      activeMenu: "/subscriptions/list",
      hidden: true
    }
  }]
};
var _default = subscriptionsRouter;
exports.default = _default;