var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _vm.data && _vm.data.user
          ? _c(
              "el-descriptions",
              {
                attrs: {
                  title: "User Details",
                  "label-style": { "font-weight": "bold" }
                }
              },
              [
                _c("el-descriptions-item", { attrs: { label: "Fullname" } }, [
                  _vm._v(" " + _vm._s(_vm.data.user.fullName) + " ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "Email" } }, [
                  _vm._v(" " + _vm._s(_vm.data.user.emailAddress) + " ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "Mobile" } }, [
                  _vm._v(" " + _vm._s(_vm.data.user.mobileNumber) + " ")
                ]),
                _c(
                  "el-descriptions-item",
                  { attrs: { label: "Billing Address", span: 3 } },
                  [_vm._v(" " + _vm._s(_vm.data.billingAddress) + " ")]
                ),
                _c(
                  "el-descriptions-item",
                  { attrs: { label: "Shipping Address", span: 3 } },
                  [_vm._v(" " + _vm._s(_vm.data.shippingAddress) + " ")]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }