"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateQuestionsAnswer = exports.getQuestionsAnswers = exports.getQuestionsAnswerById = exports.deleteQuestionsAnswer = exports.defaultQuestionsAnswerData = exports.createQuestionsAnswer = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultQuestionsAnswerData = {
  id: 0,
  enabled: true,
  questionId: undefined,
  answer: '',
  question: {
    id: 0
  }
};
exports.defaultQuestionsAnswerData = defaultQuestionsAnswerData;
var getQuestionsAnswers = function getQuestionsAnswers(params) {
  return (0, _request.default)({
    url: '/questions-answers',
    method: 'get',
    params: params
  });
};
exports.getQuestionsAnswers = getQuestionsAnswers;
var getQuestionsAnswerById = function getQuestionsAnswerById(id) {
  return (0, _request.default)({
    url: "/questions-answers/".concat(id),
    method: 'get'
  });
};
exports.getQuestionsAnswerById = getQuestionsAnswerById;
var updateQuestionsAnswer = function updateQuestionsAnswer(id, data) {
  return (0, _request.default)({
    url: "/questions-answers/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateQuestionsAnswer = updateQuestionsAnswer;
var deleteQuestionsAnswer = function deleteQuestionsAnswer(id) {
  return (0, _request.default)({
    url: "/questions-answers/".concat(id),
    method: 'delete'
  });
};
exports.deleteQuestionsAnswer = deleteQuestionsAnswer;
var createQuestionsAnswer = function createQuestionsAnswer(data) {
  return (0, _request.default)({
    url: '/questions-answers/',
    method: 'post',
    data: data
  });
};
exports.createQuestionsAnswer = createQuestionsAnswer;