"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAttribute = exports.getAttributesTree = exports.getAttributes = exports.getAttributeById = exports.fileTransferHeaders = exports.deleteAttribute = exports.defaultAttributeData = exports.createAttribute = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultAttributeData = {
  id: 0,
  enabled: true,
  name: ''
};
exports.defaultAttributeData = defaultAttributeData;
var getAttributes = function getAttributes(params) {
  return (0, _request.default)({
    url: '/attributes',
    method: 'get',
    params: params
  });
};
exports.getAttributes = getAttributes;
var getAttributesTree = function getAttributesTree(data) {
  return (0, _request.default)({
    url: '/attributes/tree',
    method: 'post',
    data: data
  });
};
exports.getAttributesTree = getAttributesTree;
var getAttributeById = function getAttributeById(id) {
  return (0, _request.default)({
    url: "/attributes/".concat(id),
    method: 'get'
  });
};
exports.getAttributeById = getAttributeById;
var updateAttribute = function updateAttribute(id, data) {
  return (0, _request.default)({
    url: "/attributes/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateAttribute = updateAttribute;
var deleteAttribute = function deleteAttribute(id) {
  return (0, _request.default)({
    url: "/attributes/".concat(id),
    method: 'delete'
  });
};
exports.deleteAttribute = deleteAttribute;
var createAttribute = function createAttribute(data) {
  return (0, _request.default)({
    url: '/attributes/',
    method: 'post',
    data: data
  });
};
exports.createAttribute = createAttribute;