var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-box",
          attrs: {
            accept: _vm.accept,
            action: "",
            "http-request": _vm.uploadFile,
            "before-remove": _vm.beforeRemove,
            "before-upload": _vm.beforeFileUpload,
            "on-remove": _vm.afterRemove,
            "on-error": _vm.onFileTransferError,
            "on-success": _vm.onFileTransferSuccess,
            "on-progress": _vm.onProgress,
            "file-list": _vm.fileList,
            "list-type": _vm.listType,
            multiple: _vm.multiple,
            drag: _vm.drag
          }
        },
        [
          _vm.drag
            ? [
                _c("i", { staticClass: "el-icon-upload" }),
                _c("div", { staticClass: "el-upload__text" }, [
                  _vm._v(" Drop file here or "),
                  _c("em", [_vm._v("click to upload")])
                ])
              ]
            : _vm.listType === "picture-card"
            ? _c("i", { staticClass: "el-icon-plus" })
            : _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v(" Click to Upload ")
              ]),
          _vm.progressPercent > 0 && _vm.progressPercent < 100
            ? _c("el-progress", {
                attrs: {
                  percentage: _vm.progressPercent,
                  color: _vm.progressColors
                }
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }