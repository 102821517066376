"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setToken = exports.setSize = exports.setSidebarStatus = exports.setLanguage = exports.removeToken = exports.getToken = exports.getSize = exports.getSidebarStatus = exports.getLanguage = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// App
var sidebarStatusKey = 'sidebar_status';
var getSidebarStatus = function getSidebarStatus() {
  return _jsCookie.default.get(sidebarStatusKey);
};
exports.getSidebarStatus = getSidebarStatus;
var setSidebarStatus = function setSidebarStatus(sidebarStatus) {
  return _jsCookie.default.set(sidebarStatusKey, sidebarStatus);
};
exports.setSidebarStatus = setSidebarStatus;
var languageKey = 'language';
var getLanguage = function getLanguage() {
  return _jsCookie.default.get(languageKey);
};
exports.getLanguage = getLanguage;
var setLanguage = function setLanguage(language) {
  return _jsCookie.default.set(languageKey, language);
};
exports.setLanguage = setLanguage;
var sizeKey = 'size';
var getSize = function getSize() {
  return _jsCookie.default.get(sizeKey);
};
exports.getSize = getSize;
var setSize = function setSize(size) {
  return _jsCookie.default.set(sizeKey, size);
};
// User
exports.setSize = setSize;
var tokenKey = 'vue_typescript_admin_access_token';
var getToken = function getToken() {
  return _jsCookie.default.get(tokenKey);
};
exports.getToken = getToken;
var setToken = function setToken(token) {
  return _jsCookie.default.set(tokenKey, token);
};
exports.setToken = setToken;
var removeToken = function removeToken() {
  return _jsCookie.default.remove(tokenKey);
};
exports.removeToken = removeToken;