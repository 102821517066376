import { render, staticRenderFns } from "./list.vue?vue&type=template&id=309dd673&scoped=true&"
import script from "./list.vue?vue&type=script&lang=ts&"
export * from "./list.vue?vue&type=script&lang=ts&"
import style0 from "./list.vue?vue&type=style&index=0&id=309dd673&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "309dd673",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Vaibhav Innovura\\Desktop\\tbadmin\\tradebridge-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('309dd673')) {
      api.createRecord('309dd673', component.options)
    } else {
      api.reload('309dd673', component.options)
    }
    module.hot.accept("./list.vue?vue&type=template&id=309dd673&scoped=true&", function () {
      api.rerender('309dd673', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/address/list.vue"
export default component.exports