"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateState = exports.getStates = exports.getStateById = exports.deleteState = exports.defaultStateData = exports.createState = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultStateData = {
  id: 0,
  enabled: true,
  countryId: undefined,
  name: '',
  country: {
    id: undefined
  }
};
exports.defaultStateData = defaultStateData;
var getStates = function getStates(params) {
  return (0, _request.default)({
    url: '/states',
    method: 'get',
    params: params
  });
};
exports.getStates = getStates;
var getStateById = function getStateById(id) {
  return (0, _request.default)({
    url: "/states/".concat(id),
    method: 'get'
  });
};
exports.getStateById = getStateById;
var updateState = function updateState(id, data) {
  return (0, _request.default)({
    url: "/states/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateState = updateState;
var deleteState = function deleteState(id) {
  return (0, _request.default)({
    url: "/states/".concat(id),
    method: 'delete'
  });
};
exports.deleteState = deleteState;
var createState = function createState(data) {
  return (0, _request.default)({
    url: '/states/',
    method: 'post',
    data: data
  });
};
exports.createState = createState;