"use strict";

var _interopRequireDefault = require("C:/Users/Vaibhav Innovura/Desktop/tbadmin/tradebridge-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAttributesValues = exports.getAttributesValuesById = exports.getAttributesValues = exports.fileTransferHeaders = exports.deleteAttributesValues = exports.defaultAttributeValuesData = exports.createAttributesValues = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultAttributeValuesData = {
  id: 0,
  enabled: true,
  name: '',
  attributeId: '',
  attribute: {
    id: null
  }
};
exports.defaultAttributeValuesData = defaultAttributeValuesData;
var getAttributesValues = function getAttributesValues(params) {
  return (0, _request.default)({
    url: '/attributes-values',
    method: 'get',
    params: params
  });
};
exports.getAttributesValues = getAttributesValues;
var getAttributesValuesById = function getAttributesValuesById(id) {
  return (0, _request.default)({
    url: "/attributes-values/".concat(id),
    method: 'get'
  });
};
exports.getAttributesValuesById = getAttributesValuesById;
var updateAttributesValues = function updateAttributesValues(id, data) {
  return (0, _request.default)({
    url: "/attributes-values/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateAttributesValues = updateAttributesValues;
var deleteAttributesValues = function deleteAttributesValues(id) {
  return (0, _request.default)({
    url: "/attributes-values/".concat(id),
    method: 'delete'
  });
};
exports.deleteAttributesValues = deleteAttributesValues;
var createAttributesValues = function createAttributesValues(data) {
  return (0, _request.default)({
    url: '/attributes-values/',
    method: 'post',
    data: data
  });
};
exports.createAttributesValues = createAttributesValues;